<template>
  <!-- 企讯云+ -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">企讯云+</div>
            <div class="backgroundImg2">
              基于不同行业、不同区域客户对于外显地的要求，我们与三大运营商进行深度合作，共同研发的AXB模式系统，将您手机号做进外呼系统，您跟客户都是接听形式，避免高频呼叫而封号。
            </div>
            <div class="backgroundImg3">
              <span class="backgroundImg3-1" @click="changesize">立即购买</span>
            </div>
          </div>
        </div>
      </header>
    </div>

    <!-- 企讯云+ -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">企讯云+</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>
      <!--  -->
      <div class="Cloud">
        <div class="Cloud1">
          <div class="Cloud1-1">
            <div style="margin-top: 71px">
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              >基于不同行业、不同区域客户对于外显地的要求，我们与三大运营商进行深度合作，共同研发的AXB模式系统，将您手机号做进外呼系统，您跟客户都是接听形式，避免高频呼叫而封号。
            </div>
            <div style="margin-top: 44px">
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              >云呼叫是运营商推出的功能完善的新一代绑定手机号业务。它可以预约充值，采用随时预约登记开通方式，通过业务控制台将您的电话由主叫变为被叫状态，完成通话业务。
            </div>
          </div>
          <div class="Cloud1-2"></div>
        </div>
        <div class="Cloud2">
          例如：张三移动手机号发起呼叫，李四联通手机号接听
        </div>
        <div class="Cloud3"></div>
        <div class="Cloud4">
          接到呼出指令后，向双方同时打出，双方皆是接通状态
        </div>
        <div class="Cloud5 flex wrap jc">
          张三输入李四的手机号
          <div class="Cloud5-1"></div>
          回拨系统同时给张三和李四各拨打一个电话
          <div class="Cloud5-1"></div> 李四接听
          <div class="Cloud5-1"></div>张三和李四开始通话
          <div class="Cloud5-1"></div> 通话结束后挂机
          <div class="Cloud5-1"></div>呼叫完成
        </div>
      </div>
    </div>

    <!-- 产品优势 -->
    <div style="background: #f8f8f8; height: 1110px">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">产品优势</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>
      <!--  -->
      <div class="advantage" style="margin-top: 60px">
        <div class="flex jc-space">
          <div class="advantage1">
            <div class="advantageImg1"></div>
            <div class="advantage1-1">防封号</div>
            <div class="advantage1-2" style="width: 313px">
              采用AxB呼叫模式，通过隐藏号码呼叫客户,专用通道号码，运营商封号策略要宽松一些，系统是基于智能的防封号算法自动限制呼叫次数和呼叫频率，有效减少封号情况。
            </div>
          </div>
          <div class="advantage1">
            <div class="advantageImg2"></div>
            <div class="advantage1-1">提升接通率</div>
            <div class="advantage1-2" style="width: 312px">
              对外显示的中间号，如果被用户标注为骚扰号码后，接通率会降低。这时只要换一个中间号。更换成本低。换主叫号后仍可继续使用当月的套餐剩余分钟数。
            </div>
          </div>
          <div class="advantage1">
            <div class="advantageImg3"></div>
            <div class="advantage1-1">话费更低</div>
            <div class="advantage1-2" style="width: 294px">
              AXB防封外呼系统语音套餐购买，资费更低。
            </div>
          </div>
        </div>
      </div>
      <div class="advantage" style="margin-top: 40px">
        <div class="flex jc-space">
          <div class="advantage1">
            <div class="advantageImg4"></div>
            <div class="advantage1-1">自由外呼</div>
            <div class="advantage1-2" style="width: 312px">
              同时支持电脑端和安卓手机端呼叫，支持客户回拨。可使用专属话机，也可以使用自己的手机外呼。
            </div>
          </div>
          <div class="advantage1">
            <div class="advantageImg5"></div>
            <div class="advantage1-1">通话统计</div>
            <div class="advantage1-2" style="width: 310px">
              电销人员工作过程统计，呼入呼出通时通次统计报表。
            </div>
          </div>
          <div class="advantage1">
            <div class="advantageImg6"></div>
            <div class="advantage1-1">自动录音</div>
            <div class="advantage1-2" style="width: 311px">
              录音文件自动同步，随时查听和下载，优秀录音团队内进行共享。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  行业应用  -->
    <div style="background: #f8f8f8">
      <div class="systemTools-1">
        <!--  -->
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">行业应用</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2">实践验证，数智未来无限可能</div>
        </div>

        <!-- 行业应用 -->
        <div class="industry" style="margin-top: 60px">
          <!-- 行业导航 -->
          <div class="industryLeft" style="">
            <div
              v-for="(item, index) in industryList"
              :key="index"
              :class="[flag2 == item.id ? 'industryLeft-1s' : 'industryLeft-1']"
              @mouseover="changeIndustry(item.id)"
            >
              {{ item.titel }}
            </div>
          </div>
          <!-- 行业详情 -->
          <div class="" style="width: 940px; height: 600px">
            <div class="swiper-container2">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <!-- <div class="" style="width:607px;height:420px;">应用领域</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">应用领域</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>随时掌握客户动态信息，做出合理计划与部署</li>
                        <li>获取广告内容打开信息，主动出击不再被动等待</li>
                        <li>快速与客户建立高效联系</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当我们业务员看到自己发出的朋友圈活动广告信息被客户打开十几次的时候，我们就知道这个客户离下单不远了，这时我们会及时给这个客户再发一封详尽的产品介绍，提前做好下一步营销准备，我们很多客户都是这样成交的。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>企业服务</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">企业服务</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>随时掌握客户动态信息，做出合理计划与部署</li>
                        <li>获取广告内容打开信息，主动出击不再被动等待</li>
                        <li>快速与客户建立高效联系</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当我们业务员看到自己发出的朋友圈活动广告信息被客户打开十几次的时候，我们就知道这个客户离下单不远了，这时我们会及时给这个客户再发一封详尽的产品介绍，提前做好下一步营销准备，我们很多客户都是这样成交的。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>零售行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">零售行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>可以帮你解决营销电话过多被封号的问题</li>
                        <li>依据评分优先处理销售线索</li>
                        <li>让业务员和客户微信互动快捷简单</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        方大SCRM给我们公司开展业务提供了新的思路和现实的解决方案，电销的解决方案和微信私域流量的裂变为我们公司获客提供了全新的理念，对我们互联网服务企业真是一款不可多得好产品
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>教育行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">教育行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>借助私域流量，发现更多潜在客户</li>
                        <li>沉淀客户数据资产，高效管理每个客户</li>
                        <li>在微信中持续给客户发送内容种草，促进成交</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当时选择方大SCRM的主要原因是
                        方大SCRM使用起来太方便了。所有客户资料在手机上一目了然，通过这个系统管理员工和客户效率提升不少，员工开发的客户情况和跟进情况我都能通过这个系统来掌握了。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>装修行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">装修行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>全方位勾勒客户画像，快速了解客户个人风格和喜好</li>
                        <li>有效盘活沉睡客户，强化客户管理中的日常维护</li>
                        <li>利用标签与分组，实现客户层级化管理</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        方大SCRM容素材包：提供文章、文件、海报、商品等丰富的内容素材包；可以根据客户的标签给客户发送对应的素材，根据客户的浏览轨迹，分析客户心理，促进客户合作签单
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>地产公司</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">地产公司</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>通过销售漏斗，指导业务过程精细化、数据化</li>
                        <li>方大可以自动筛选，自动分配给销售跟进</li>
                        <li>
                          通过方大提供的CRM组件，帮你沉淀客户数据资产，高效管理每一个客户
                        </li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当我们业务员看到自己发出的朋友圈活动广告信息被客户打开十几次的时候，我们就知道这个客户离下单不远了，这时我们会及时给这个客户再发一封详尽的产品介绍，提前做好下一步营销准备，我们很多客户都是这样成交的。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>金融行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">金融行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>快速准确找到意向客户资源</li>
                        <li>有效管理加盟商信息</li>
                        <li>利用标签与分组，实现加盟代理商层级化管理</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        之前我们公司有多年的客户数据沉淀，使用方大SCRM后可以很好地对这些沉淀的数据进行科学的分组分类、贴标签等操作，从而有针对性地营销，做到多次触达盘活老客户。而针对暂时没有客户资源的新员工来说，公海就是他们优质的资源库，可以自由领取使用跟进，快速成长。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>医美行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">医美行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>给一线销售制定合理的任务计划</li>
                        <li>通过量化指标，查看进度，保证任务的执行落地</li>
                        <li>规范化沉淀客户资源</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        根据销售和客户的互动行为，以及客户在内容中留下的行为轨迹，智能计算得出互动指数。销售可以结合互动指数，对不同层级的客户使用针对策略去跟进，促使销售转化率达到目标或预期
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>休闲娱乐行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">休闲娱乐行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>快速与客户建立高效联系潜能</li>
                        <li>快速准确找到意向客户资源利润</li>
                        <li>借助人工智能实现个性化推荐潜客</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        之前我们公司有多年的客户数据沉淀，使用方大SCRM后可以很好地对这些沉淀的数据进行科学的分组分类、贴标签等操作，从而有针对性地营销，做到多次触达盘活老客户。而针对暂时没有客户资源的新员工来说，公海就是他们优质的资源库，可以自由领取使用跟进，快速成长。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="swiper-pagination"></div> -->
              <!-- <div class="swiper-scrollbar"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-top></footer-top>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import footerTop from "@/components/footerTop.vue";
import Footer from "@/components/footer.vue";
import { Swiper, Navigation, Pagination, Autoplay, Scrollbar } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar]);
import eventBus from '../config/eventBus.js'
export default {
  name: "",
  components: {
    HeadNav,
    footerTop,
    Footer,
  },
  data() {
    return {
      industryList: [
        {
          id: 0,
          titel: "应用领域",
        },
        {
          id: 1,
          titel: "企业服务",
        },
        {
          id: 2,
          titel: "零售行业",
        },
        {
          id: 3,
          titel: "教育行业",
        },
        {
          id: 4,
          titel: "装修行业",
        },
        {
          id: 5,
          titel: "地产公司",
        },
        {
          id: 6,
          titel: "金融行业",
        },
        {
          id: 7,
          titel: "医美行业",
        },
        {
          id: 8,
          titel: "休闲娱乐行业",
        },
      ],
      flag2: 0,
      Myswiper2: null,
    };
  },
  mounted() {
    var that = this;
    // this.timeChange();
    that.Myswiper2 = new Swiper(".swiper-container2", {

      direction: "vertical",
      navigation: false,
      on: {
        transitionEnd: function () {
          that.flag2 = this.activeIndex;
        },
      },
    });
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    changesize(){
      eventBus.$emit('add',true)
    },
    changeIndustry(e) {
      // console.log(e,'654')
      this.flag2 = e;
      this.Myswiper2.slideTo(e, 1000, false);
    },
  },
};
</script>

<style src="@/style/QiXunCloud+.css" scoped></style>
<style lang="scss" scoped>
.swiper-container2 {
  width: 960px;
  height: 600px;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

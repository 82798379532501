<template>
  <!-- 客户案列详情  -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">客户案例</div>
            <div class="backgroundImg2">为您提供专业的行业解决方案</div>
          </div>
        </div>
      </header>
    </div>

    <!-- 教育行业案例 -->
    <div style="width: 100%; background: #f8f8f8">
      <div class="case">
        <div class="case1">教育行业 | 学霸君：智能客服打造教育</div>
        <!-- 使用产品 -->
        <div class="product">
          <div class="product1">
            <div class="product1-1">使用产品</div>
            <div class="product1-2">TRIAL PRODUCT</div>
          </div>
          <div class="product2">
            <div class="product2-1">
              <div class="productImg1"></div>
              <div class="productImg2">CDN</div>
            </div>
            <div class="product2-2">
              <div class="productImg1"></div>
              <div class="productImg2">CDN</div>
            </div>
            <div class="product2-3">
              <div class="productImg1"></div>
              <div class="productImg2">CDN</div>
            </div>
          </div>
        </div>
        <!-- 客户背景 -->
        <div class="customer">
          <div class="product1">
            <div class="product1-1">客户背景</div>
            <div class="product1-2">CUSTOMER BACKGROUND</div>
          </div>
          <div class="customer2">
            <div class="customer2-1"></div>
            <div class="customer2-2">
              <div>学霸君是一家中小学智能化在线教育公司，成立于2012年。</div>
              <div>
                该公司致力于探索与实现人工智能在教育领域的应用，其研发的智能教育机器人Aidam参加数学高考，与状元同台PK并以134分成绩获胜，旗下拥有学霸君1对1等辅导产品。
              </div>
            </div>
          </div>
        </div>
        <!-- 解决方案 -->
        <div class="programme">
          <div class="product1">
            <div class="product1-1">解决方案</div>
            <div class="product1-2">SOLUTION</div>
          </div>
          <div class="programme1">
            <div class="programme1-1">
              <div class="flex">
                <div class="programme1span">1</div>
                <div class="programme1span1"></div>
              </div>
              <div class="programme1-1-2">宣传推广效率低下</div>
            </div>
            <div class="programme1-2" style="height: 38px">
              <div>
                主动外呼彻底打破了传统电话销售中的手动拨号、手动录入的流程，转为由系统自动呼出，将已接通客户自动分配至空闲坐席的方式，
              </div>
              <div>
                极大的提升电话营销的呼出效率，据实践统计人均有效通话数可达500-680次，进而提高成单效率。
              </div>
            </div>
          </div>
          <div class="programme1">
            <div class="programme1-1">
              <div class="flex">
                <div class="programme1span">2</div>
                <div class="programme1span1"></div>
              </div>
              <div class="programme1-1-2">语音外呼</div>
            </div>
            <div class="programme1-2" style="height: 38px">
              <div>
                系统自动外呼客户手机，客户摘机后系统自动播放语音广告，也可按键引导客户查听相关语音，
              </div>
              <div>
                语音广告可提示客户如感兴趣可按键转入座席应答处理。
              </div>
            </div>
          </div>
          <div class="programme1">
            <div class="programme1-1">
              <div class="flex">
                <div class="programme1span">3</div>
                <div class="programme1span1"></div>
              </div>
              <div class="programme1-1-2">信息群发</div>
            </div>
            <div class="programme1-2" style="height: 14px">
              <div>
                呼叫中心可对接短信平台、邮件服务，对指定学员发送营销推广信息、服务关怀信息等。
              </div>
            </div>
          </div>
          <div class="programme1">
            <div class="programme1-1">
              <div class="flex">
                <div class="programme1span">4</div>
                <div class="programme1span1"></div>
              </div>
              <div class="programme1-1-2">对接其他入口</div>
            </div>
            <div class="programme1-2" style="height: 14px">
              <div>
                呼叫中心不仅可以管理电话入口，还对接业务推广网站、微信公众账号等。为学员咨询、服务、营销提供更多、更便捷的服务。
              </div>
            </div>
          </div>
          <div class="programme1">
            <div class="programme1-1">
              <div class="flex">
                <div class="programme1span">5</div>
                <div class="programme1span1"></div>
              </div>
              <div class="programme1-1-2">学员信息记录</div>
            </div>
            <div class="programme1-2" style="height: 38px">
              <div>
                使用专业CRM统一管理所有意向学员的线索，将学员线索掌握在企业手里。天天讯通呼叫中心提供专业CRM，界面操作简单，灵活易用。
              </div>
              <div>
                同时提供学员跟进提醒。通过对线索的来电号码、来电时间和通话记录的整合，实现高效的客户线索统一管理和分配。
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <div style="width: 1200px; padding-bottom: 82px">
          <div class="back">返回</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "",
  components: {
    HeadNav,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {
    // this.timeChange();
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {},
};
</script>

<style src="@/style/CustomerCaseDetails.css" scoped></style>
<style lang="scss" scoped></style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "@/style/box.css";
import "element-ui/lib/theme-chalk/index.css";

// import Swiper from 'swiper';

import "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";

Vue.use(ElementUI);
// Vue.use(Swiper);

Vue.config.productionTip = false;

router.afterEach((to, from, next) => {
  // console.log(to)
  // console.log(from)
  // console.log(next)
  window.scrollTo(0, 0);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

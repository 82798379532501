<template>
  <!-- 会员登录 -->
  <div class="loginbackground">
    <head-nav></head-nav>
    <div class="login" style="">
      <div class="box">
        <div class="loginLeft"></div>
        <div class="loginRight">
          <div class="loginRight1">会员登录</div>
          <div class="loginRight2">
            <div class="loginRight2-1"></div>
            <div style="margin-left: 24px">
              <el-input
                v-model="form.userName"
                placeholder="请输入用户名"
                type="text"
                style="width: 300px"
              />
            </div>
          </div>
          <div class="loginRight3">
            <div class="loginRight3-1"></div>
            <div style="margin-left: 27px">
              <el-input
                v-model="form.password"
                placeholder="请输入密码"
                type="text"
                style="width: 300px"
              />
            </div>
          </div>
          <div class="loginRight4">
            <div class="loginRight4Left">
              <div class="loginRight4-1"></div>
              <div style="margin-left: 28px">
                <el-input
                  v-model="form.code"
                  placeholder="请输入手机验证码"
                  type="text"
                />
              </div>
            </div>
            <div class="loginRight4Right"><span style="cursor: pointer;">获取验证码</span></div>
          </div>
          <div class="loginRight5"><span style="cursor: pointer;">会员登陆</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
export default {
  name: "",
  components: {
    HeadNav,
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
        code: "",
      },
    };
  },
  mounted() {
    // this.timeChange();
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {},
};
</script>

<style src="@/style/MemberLogin.css" scoped></style>
<style lang="scss" scoped></style>

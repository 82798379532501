<template>
  <!-- 企业动态 -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">企业动态</div>
            <div class="backgroundImg2">ENTERPRISE DYNAMICS</div>
          </div>
        </div>
      </header>
    </div>

    <!--  -->
    <div style="width: 100%">
      <div class="information">
        <div
          class="information1"
          v-for="(item, index) in information"
          :key="index"
        >
          <div class="information1-1"></div>
          <div class="information1-2">{{ item.titel }}</div>
          <div class="information1-3">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <footer-top></footer-top>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import footerTop from "@/components/footerTop.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "",
  components: {
    HeadNav,
    footerTop,
    Footer,
  },
  data() {
    return {
      information: [
        {
          imgUrl: "",
          titel: "新闻资讯",
          content: "新闻资讯新闻资讯新闻资讯新闻资讯新闻资讯",
        },
        {
          imgUrl: "",
          titel: "新闻资讯",
          content: "新闻资讯新闻资讯新闻资讯新闻资讯",
        },
        {
          imgUrl: "",
          titel: "新闻资讯",
          content: "新闻资讯新闻资讯新闻资讯新闻资讯",
        },
        {
          imgUrl: "",
          titel: "新闻资讯",
          content: "新闻资讯新闻资讯新闻资讯",
        },
        {
          imgUrl: "",
          titel: "新闻资讯",
          content: "新闻资讯新闻资讯新闻资讯",
        },
      ],
    };
  },
  mounted() {
    // this.timeChange();
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {},
};
</script>

<style src="@/style/EnterpriseDynamics.css" scoped></style>
<style lang="scss" scoped></style>

<template>
  <!-- 下载中心 -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">下载中心</div>
            <div class="backgroundImg2">DOWNLOAD</div>
          </div>
        </div>
      </header>
    </div>

    <!--  -->
    <div class="download">
      <div class="download1" style="margin-top: 76px">
        <div class="download1-1">方大智能管家有什么优势？</div>
        <div class="download1-2"></div>
      </div>
      <div class="download1">
        <div class="download1-1">方大智能管家有什么优势？</div>
        <div class="download1-2"></div>
      </div>
      <div class="download1">
        <div class="download1-1">方大智能管家有什么优势？</div>
        <div class="download1-2"></div>
      </div>
      <div class="download1">
        <div class="download1-1">方大智能管家有什么优势？</div>
        <div class="download1-2"></div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "",
  components: {
    HeadNav,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {
    // this.timeChange();
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {},
};
</script>

<style src="@/style/DownloadCenter.css" scoped></style>
<style lang="scss" scoped></style>

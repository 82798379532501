<template>
  <div id="app">
    <!--<HeadNav></HeadNav>-->
    <router-view></router-view>

    <!-- 全局悬浮窗 -->
    <div class="suspension">
      <div class="scanCode" style="">
        <div class="flex ja">
          <div class="scanCodeimg"></div>
        </div>
        <div>扫码咨询</div>
        <div class="scanCodeHover">
          <div class="code1"></div>
        </div>
      </div>
      <div style="width: 100%;height: 2px; background-color: white;"></div>
      <div class="phone" style="">
        <div class="flex ja">
          <div class="phoneimg"></div>
        </div>
        <div>销售热线</div>
        <div class="phoneHover">
          <div style="color: black;">李经理：</div>
          <div style="margin:10px 0;">&nbsp;&nbsp;&nbsp;18728402600</div>
          <div style="width: 100%;height: 8px;"></div>
          <div style="color: black;">张经理：</div>
          <div style="margin:10px 0;">&nbsp;&nbsp;&nbsp;18030461922</div>
          <div style="width: 100%;height: 8px;"></div>
          <div style="color: black;">袁经理：</div>
          <div style="margin:10px 0;">&nbsp;&nbsp;&nbsp;18728402436</div>
        </div>
      </div>
      <div style="width: 100%;height: 2px; background-color: white;"></div>
      <div class="blacktop" style="" @click="blackTop">
        <div class="flex ja">
          <div class="blacktopimg"></div>
        </div>
        <div>返回顶部</div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
// import HeadNav from "@/components/headNav.vue";
// import Footer from "@/components/footer.vue";

export default {
  components: {
    // HeadNav,
    // Footer
  },

  data() {
    return {
      flag: true,
    };
  },
  created() {
    //首次进入页面
    // if (window.performance.navigation.type != 1) {
    //   let url = window.location.href.split("#")[1];
    //   localStorage.setItem("toUrlData", url);
    // }
    // let data = localStorage.getItem("toUrlData");
    // if (data) {
    //   this.toUrl = data;
    // }
    // this.$router.beforeEach((to, from, next)=>{
    //   this.toUrl=to.path
    //   next();
    // })
  },
  // watch: {
  //   '$route': function(to,form) {
  //     if(this.flag == false) {
  //       console.log(to)
  //       console.log(form)
  //     }
  //     document.body.scrollTop = 0
  //     document.documentElement.scrollTop = 0
  //   }
  // },
  mounted() {},
  methods: {
    //返回顶部
    blackTop() {
      // window.scrollTo(0, 0);
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果 
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 20;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 1);
    }

  },

};
</script>

<!-- <style>
  @import "@/style/box.css";
</style> -->
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  word-break: break-all;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  min-width: 1200px;
  margin: 0;
  padding: 0;
}
.suspension {
  background-color: #FE8A46;
  border-radius: 10px;
  position: fixed;
  right: 20px;
  bottom: 15vh;
  color: white;
  z-index: 13;
}
.scanCode {
  padding: 5px;
  margin: 10px 0;
  cursor: pointer;
}
.scanCode:hover .scanCodeHover{
  display: inline;
  opacity: 1;
}
.scanCodeHover {
  display: none;
  position: absolute;
  top: 0px;
  left: -130px;
  width: 120px;
  opacity: 0; 
  transition: opacity 2s;
  -webkit-transition: opacity 2s;
}
.phone:hover .phoneHover{
  display: inline;
  opacity: 1;
}
.phoneHover {
  position: absolute;
  display: none;
  top: 0px;
  left: -150px;
  width: 120px;
  padding: 10px;
  background: #FE8A46;
  border-radius: 10px;
  opacity: 0; 
  transition: opacity 2s;
  -webkit-transition: opacity 2s;
}
.code1 {
  width: 120px;
  height: 120px;
  background-image: url("./assets/02首页版本二/code.png");
  background-size: 100% 100%;
  border-radius: 10px;
}
.phone {
  padding: 5px;
  margin: 10px 0;
}
.blacktop {
  padding: 5px;
  margin: 10px 0;
  cursor: pointer;
}
.scanCodeimg {
  width: 30px;
  height: 30px;
  background-image: url("./assets/02首页版本二/information.png");
  background-size: 100% 100%;
}
.phoneimg {
  width: 30px;
  height: 30px;
  background-image: url("./assets/02首页版本二/phone.png");
  background-size: 100% 100%;
}
.blacktopimg {
  width: 30px;
  height: 30px;
  background-image: url("./assets/02首页版本二/blackTop.png");
  background-size: 100% 100%;
}
</style>

<template>
  <div class="error-container">服务支持</div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  mounted() {
    // this.timeChange();
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>

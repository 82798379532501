<template>
  <!-- 企讯宝 -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">企讯宝</div>
            <div class="backgroundImg2">
              <div>方大呼叫中心，基于云计算，上线速度快</div>
              <div>全渠道接入，各地区归属地，随时随地接入服务</div>
              <div>座席按需购买，即开即用，无需购置软硬件设施，前期投入少</div>
              <div>号码资源丰富，全网运营商线路接入，系统稳定性高达99%</div>
            </div>
            <div class="backgroundImg3">
              <span class="backgroundImg3-1" @click="changesize">立即购买</span>
            </div>
          </div>
        </div>
      </header>
    </div>

    <!--   用户的困扰   -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">用户的困扰</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>
      <!--  -->
      <div class="user">
        <div class="user1" style="flex: 1">
          <div class="user1-1" style="margin: 57px 31px 0 0">
            <div class="user1-1-1">功能无法满足需求</div>
          </div>
          <div class="user1-1" style="margin: 42px 106px 0 0">
            <div class="user1-1-1">客户资源被泄露</div>
          </div>
          <div class="user1-1" style="margin: 70px 39px 0 0">
            <div class="user1-1-1">通话音质不稳定</div>
          </div>
        </div>
        <div class="user2" style=""></div>
        <div class="user3" style="flex: 1">
          <div class="user3-1" style="margin: 36px 0 0 29px">
            <div class="user1-1-1">座席进电话慢</div>
          </div>
          <div class="user3-1" style="margin: 55px 0 0 109px">
            <div class="user1-1-1">售后不及时</div>
          </div>
          <div class="user3-1" style="margin: 60px 0 0 69px">
            <div class="user1-1-1">号码标记太多</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 企讯宝概述 -->
    <div class="systemTools" style="height: 549px; background: #f8f8f8">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">企讯宝概述</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>
      <!--  概述  -->
      <div class="summary" style="">
        <div class="summary1"></div>
        <div class="summary2">
          <div>方大呼叫中心，基于云计算，上线速度快</div>
          <div>全渠道接入，各地区归属地，随时随地接入服务</div>
          <div>座席按需购买，即开即用，无需购置软硬件设施，前期投入少</div>
          <div>号码资源丰富，全网运营商线路接入，系统稳定性高达99%</div>
        </div>
        <div class="summary3"></div>
      </div>
      <div>
        <div class="summary4"><span class="summary4-1" @click="changesize">点击申请使用</span></div>
      </div>
    </div>

    <!-- 企讯宝功能 -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">企讯宝功能</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>
      <!--  功能  -->
      <div class="function">
        <div class="function1">
          <div class="functionImg1"></div>
          <div class="function1-2">批量导入</div>
        </div>
        <div class="function1">
          <div class="functionImg2"></div>
          <div class="function1-2">智能外呼</div>
        </div>
        <div class="function1">
          <div class="functionImg3"></div>
          <div class="function1-2">通话录音</div>
        </div>
        <div class="function1">
          <div class="functionImg4"></div>
          <div class="function1-2">外呼话务</div>
        </div>
        <div class="function1">
          <div class="functionImg5"></div>
          <div class="function1-2">客户管理</div>
        </div>
        <div class="function1">
          <div class="functionImg6"></div>
          <div class="function1-2">历史记录</div>
        </div>
      </div>
      <div class="function">
        <div class="function1">
          <div class="functionImg7"></div>
          <div class="function1-2">来电提醒</div>
        </div>
        <div class="function1">
          <div class="functionImg8"></div>
          <div class="function1-2">IVR导航</div>
        </div>
        <div class="function1">
          <div class="functionImg9"></div>
          <div class="function1-2">坐席分配</div>
        </div>
        <div class="function1">
          <div class="functionImg10"></div>
          <div class="function1-2">工单管理</div>
        </div>
        <div class="function1">
          <div class="functionImg11"></div>
          <div class="function1-2">智能语音</div>
        </div>
        <div class="function1">
          <div class="functionImg12"></div>
          <div class="function1-2">实时监控</div>
        </div>
      </div>
    </div>

    <!-- 产品优势 -->
    <div class="systemTools" style="background: #f8f8f8; height: 631px">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">产品优势</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <!--  -->
      <div class="advantage">
        <div class="advantage1">
          <div class="advantageImg1"></div>
          <div class="advantageTypeface1">快速部署</div>
          <div class="advantageTypeface2" style="width: 190px">
            傻瓜式安装，1分钟即可轻松上手快捷使用
          </div>
        </div>
        <div class="advantage1">
          <div class="advantageImg2"></div>
          <div class="advantageTypeface1">成本更低</div>
          <div class="advantageTypeface2" style="width: 194px">
            无需购置服务器，无需专业运维人员按需添加营销人员
          </div>
        </div>
        <div class="advantage1">
          <div class="advantageImg3"></div>
          <div class="advantageTypeface1">通信稳定</div>
          <div class="advantageTypeface2" style="width: 192px">
            支持4G全网通与SIP固话信号无干扰，清晰稳定
          </div>
        </div>
        <div class="advantage1">
          <div class="advantageImg4"></div>
          <div class="advantageTypeface1">高效工作</div>
          <div class="advantageTypeface2" style="width: 192px">
            日均通话量达到600-800人次自动拨号，智能挂机
          </div>
        </div>
      </div>
    </div>
    <!--  行业应用  -->
    <div style="background: #f8f8f8">
      <div class="systemTools-1">
        <!--  -->
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">行业应用</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2">实践验证，数智未来无限可能</div>
        </div>

        <!-- 行业应用 -->
        <div class="industry" style="margin-top: 60px">
          <!-- 行业导航 -->
          <div class="industryLeft" style="">
            <div
              v-for="(item, index) in industryList"
              :key="index"
              :class="[flag2 == item.id ? 'industryLeft-1s' : 'industryLeft-1']"
              @mouseover="changeIndustry(item.id)"
            >
              {{ item.titel }}
            </div>
          </div>
          <!-- 行业详情 -->
          <div class="" style="width: 940px; height: 600px">
            <div class="swiper-container2">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <!-- <div class="" style="width:607px;height:420px;">应用领域</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">应用领域</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>随时掌握客户动态信息，做出合理计划与部署</li>
                        <li>获取广告内容打开信息，主动出击不再被动等待</li>
                        <li>快速与客户建立高效联系</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当我们业务员看到自己发出的朋友圈活动广告信息被客户打开十几次的时候，我们就知道这个客户离下单不远了，这时我们会及时给这个客户再发一封详尽的产品介绍，提前做好下一步营销准备，我们很多客户都是这样成交的。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>企业服务</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">企业服务</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>随时掌握客户动态信息，做出合理计划与部署</li>
                        <li>获取广告内容打开信息，主动出击不再被动等待</li>
                        <li>快速与客户建立高效联系</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当我们业务员看到自己发出的朋友圈活动广告信息被客户打开十几次的时候，我们就知道这个客户离下单不远了，这时我们会及时给这个客户再发一封详尽的产品介绍，提前做好下一步营销准备，我们很多客户都是这样成交的。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>零售行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">零售行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>可以帮你解决营销电话过多被封号的问题</li>
                        <li>依据评分优先处理销售线索</li>
                        <li>让业务员和客户微信互动快捷简单</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        方大SCRM给我们公司开展业务提供了新的思路和现实的解决方案，电销的解决方案和微信私域流量的裂变为我们公司获客提供了全新的理念，对我们互联网服务企业真是一款不可多得好产品
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>教育行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">教育行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>借助私域流量，发现更多潜在客户</li>
                        <li>沉淀客户数据资产，高效管理每个客户</li>
                        <li>在微信中持续给客户发送内容种草，促进成交</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当时选择方大SCRM的主要原因是
                        方大SCRM使用起来太方便了。所有客户资料在手机上一目了然，通过这个系统管理员工和客户效率提升不少，员工开发的客户情况和跟进情况我都能通过这个系统来掌握了。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>装修行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">装修行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>全方位勾勒客户画像，快速了解客户个人风格和喜好</li>
                        <li>有效盘活沉睡客户，强化客户管理中的日常维护</li>
                        <li>利用标签与分组，实现客户层级化管理</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        方大SCRM容素材包：提供文章、文件、海报、商品等丰富的内容素材包；可以根据客户的标签给客户发送对应的素材，根据客户的浏览轨迹，分析客户心理，促进客户合作签单
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>地产公司</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">地产公司</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>通过销售漏斗，指导业务过程精细化、数据化</li>
                        <li>方大可以自动筛选，自动分配给销售跟进</li>
                        <li>
                          通过方大提供的CRM组件，帮你沉淀客户数据资产，高效管理每一个客户
                        </li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当我们业务员看到自己发出的朋友圈活动广告信息被客户打开十几次的时候，我们就知道这个客户离下单不远了，这时我们会及时给这个客户再发一封详尽的产品介绍，提前做好下一步营销准备，我们很多客户都是这样成交的。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>金融行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">金融行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>快速准确找到意向客户资源</li>
                        <li>有效管理加盟商信息</li>
                        <li>利用标签与分组，实现加盟代理商层级化管理</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        之前我们公司有多年的客户数据沉淀，使用方大SCRM后可以很好地对这些沉淀的数据进行科学的分组分类、贴标签等操作，从而有针对性地营销，做到多次触达盘活老客户。而针对暂时没有客户资源的新员工来说，公海就是他们优质的资源库，可以自由领取使用跟进，快速成长。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>医美行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">医美行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>给一线销售制定合理的任务计划</li>
                        <li>通过量化指标，查看进度，保证任务的执行落地</li>
                        <li>规范化沉淀客户资源</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        根据销售和客户的互动行为，以及客户在内容中留下的行为轨迹，智能计算得出互动指数。销售可以结合互动指数，对不同层级的客户使用针对策略去跟进，促使销售转化率达到目标或预期
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>休闲娱乐行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">休闲娱乐行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>快速与客户建立高效联系潜能</li>
                        <li>快速准确找到意向客户资源利润</li>
                        <li>借助人工智能实现个性化推荐潜客</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        之前我们公司有多年的客户数据沉淀，使用方大SCRM后可以很好地对这些沉淀的数据进行科学的分组分类、贴标签等操作，从而有针对性地营销，做到多次触达盘活老客户。而针对暂时没有客户资源的新员工来说，公海就是他们优质的资源库，可以自由领取使用跟进，快速成长。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="swiper-pagination"></div> -->
              <!-- <div class="swiper-scrollbar"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-top></footer-top>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import footerTop from "@/components/footerTop.vue";
import Footer from "@/components/footer.vue";
import { Swiper, Navigation, Pagination, Autoplay, Scrollbar } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar]);
import eventBus from '../config/eventBus.js'
export default {
  name: "",
  components: {
    HeadNav,
    footerTop,
    Footer,
  },
  data() {
    return {
      industryList: [
        {
          id: 0,
          titel: "应用领域",
        },
        {
          id: 1,
          titel: "企业服务",
        },
        {
          id: 2,
          titel: "零售行业",
        },
        {
          id: 3,
          titel: "教育行业",
        },
        {
          id: 4,
          titel: "装修行业",
        },
        {
          id: 5,
          titel: "地产公司",
        },
        {
          id: 6,
          titel: "金融行业",
        },
        {
          id: 7,
          titel: "医美行业",
        },
        {
          id: 8,
          titel: "休闲娱乐行业",
        },
      ],
      flag2: 0,
      Myswiper2: null,
    };
  },
  mounted() {
    var that = this;
    // this.timeChange();
    that.Myswiper2 = new Swiper(".swiper-container2", {

      direction: "vertical",
      navigation: false,
      on: {
        transitionEnd: function () {
          that.flag2 = this.activeIndex;
          // console.log(that.flag2);
        },
      },
    });
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    changesize(){
        eventBus.$emit('add',true)
    },
    changeIndustry(e) {
      // console.log(e,'654')
      this.flag2 = e;
      this.Myswiper2.slideTo(e, 1000, false);
    },
  },
};
</script>

<style src="@/style/QiXunBao.css" scoped></style>
<style lang="scss" scoped>
.swiper-container2 {
  width: 960px;
  height: 600px;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

<template>
  <!-- 解决方案 -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">深入洞察行业需求</div>
            <div class="backgroundImg2">为你提供专业的行业解决方案</div>
          </div>
        </div>
      </header>
    </div>

    <!-- 方案示例 -->
    <div style="width: 100%">
      <div class="programme">
        <div
          v-for="(item, index) in programme"
          :key="index"
          :class="index == 1 || index == 4 ? 'programme1s' : 'programme1'"
          @click="changeProgramme(item.route)"
        >
          <div class="programmeImg">
            <el-image style="width: 100%; height: 100%" :src="item.imgUrl" alt=""></el-image>
          </div>
          <div class="programme1-1">{{ item.title }}</div>
          <div class="programme1-2">{{ item.content }}</div>
          <div class="programme1-3">查看更多</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "",
  components: {
    HeadNav,
    Footer,
  },
  data() {
    return {
      programme: [
        {
          title: "教育行业解决方案",
          content:
            "传统教育培训行业只通过电视、广告媒体等方式进行业务宣传推广，效果难以达...",
          imgUrl: require('@/assets/08解决方案/education.jpg'),
          route: "/solutionDetails",
        },
        {
          title: "金融行业解决方案",
          content:
            "客服部门坐席工作处于严重饱和状态，每天电话咨询量非常大，线路拥挤现象严...",
          imgUrl: require('@/assets/08解决方案/finance.jpg'),
          route: "/automobileIndustry",
        },
        {
          title: "房产行业解决方案",
          content:
            "随着互联网的发展和国家对大健康大医疗的政策支持，民众对医疗保健行业的服...",
          imgUrl: require('@/assets/08解决方案/houseproperty.jpg'),
          route: "/realEstateIndustry",
        },
      ],
    };
  },
  mounted() {
    // this.timeChange();
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    changeProgramme(e) {
      this.$router.push(e);
    },
  },
};
</script>

<style src="@/style/Solution.css" scoped></style>
<style lang="scss" scoped></style>

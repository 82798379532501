<template>
  <!-- 企讯SCRM -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">企讯SCRM</div>
            <div class="backgroundImg2">
              用企业微信营销拓客，社群营销，CRM管理，精细化运营私域流量
              客户引流，客户运营，客户管理，销售管理，客户关系更近，成交转化更多，企业业绩更好
            </div>
            <div class="backgroundImg3">
              <span class="backgroundImg3-1" @click="changesize">立即咨询</span>
            </div>
          </div>
        </div>
      </header>
    </div>
    <!--  SCRM系统工具    -->
    <div class="systemTools" style="">
      <div class="systemTools-1">
        <!-- 工具导航 -->
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">企业微信SCRM系统工具</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2">赋能销售获客转化的SCRM产品</div>
        </div>
        <!-- 工具 -->
        <div class="systemTools2">
          <div class="flex">
            <div class="systemTools2-1">
              <div class="systemTools2-1-1 systemToolsImg1"></div>
              <div class="systemTools2-1-2">聊天会话存档</div>
              <div class="systemTools2-1-3">
                <div style="line-height: 16px;">通过企业微信会话存档</div>
                <div style="line-height: 30px">监控管理降低风险 分析工作量</div>
              </div>
            </div>
            <div class="systemTools2-1">
              <div class="systemTools2-1-1 systemToolsImg2"></div>
              <div class="systemTools2-1-2">营销拓客</div>
              <div class="systemTools2-1-3">
                <div style="line-height: 16px">抽奖 拉人 红包等一系列裂变</div>
                <div style="line-height: 30px">营销功能支持</div>
              </div>
            </div>
            <div class="systemTools2-1">
              <div class="systemTools2-1-1 systemToolsImg3"></div>
              <div class="systemTools2-1-2">群营销</div>
              <div class="systemTools2-1-3">
                <div style="line-height: 16px">完善的企业微信群功能包括</div>
                <div style="line-height: 30px">
                  自动拉群群营销 群管理 群分析等
                </div>
              </div>
            </div>
            <div class="systemTools2-1">
              <div class="systemTools2-1-1 systemToolsImg4"></div>
              <div class="systemTools2-1-2">SCRM</div>
              <div class="systemTools2-1-3">
                <div style="line-height: 16px">客户看板 销售漏斗 跟踪提醒</div>
                <div style="line-height: 30px">客户分层 公海管理等功能</div>
              </div>
            </div>
          </div>

          <div class="flex" style="margin: 59px 0 79px 0">
            <div class="systemTools2-1">
              <div class="systemTools2-1-1 systemToolsImg5"></div>
              <div class="systemTools2-1-2">渠道二维码</div>
              <div class="systemTools2-1-3">
                <div style="line-height: 16px">一人一码 一店一码 加人</div>
                <div style="line-height: 30px">详细分析 自动标签 自动回复</div>
              </div>
            </div>
            <div class="systemTools2-1">
              <div class="systemTools2-1-1 systemToolsImg6"></div>
              <div class="systemTools2-1-2">内容引擎</div>
              <div class="systemTools2-1-3">
                <div style="line-height: 16px">通企业微信聊天侧边栏的功能</div>
                <div style="line-height: 30px">实现高效沟通 精细化客户运营</div>
              </div>
            </div>
            <div class="systemTools2-1">
              <div class="systemTools2-1-1 systemToolsImg7"></div>
              <div class="systemTools2-1-2">客户画像</div>
              <div class="systemTools2-1-3">
                <div style="line-height: 16px">自定义属性 客户CRM 客户分层</div>
                <div style="line-height: 30px">客户轨迹等功能支持</div>
              </div>
            </div>
            <div class="systemTools2-1">
              <div class="systemTools2-1-1 systemToolsImg8"></div>
              <div class="systemTools2-1-2">销售SOP</div>
              <div class="systemTools2-1-3">
                <div style="line-height: 16px">各种营销销售SOP支持包括群发</div>
                <div style="line-height: 30px">群营销，销售跟踪等</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  SCRM功能  -->
    <div style="background: #f8f8f8">
      <div class="systemTools-1">
        <!--  -->
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">企业微信SCRM功能</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2">
            精细化 精准化管理运营客户 二次营销 提高客户转化率
          </div>
        </div>
        <!--  -->
        <div
          class="flex"
          style="
            margin-top: 60px;
            background: white;
            box-shadow: 0px 0px 13px 0px rgba(255, 102, 0, 0.14);
          "
        >
          <div class="SCRM-1 flex ja" @click="change1">
            <div
              class="systemTools-2-1"
              style="width: 56px; height: 56px; margin-right: 19px"
            ></div>
            <div :class="[flag == 0 ? 'SCRM-1-1s' : 'SCRM-1-1']">
              会话存档功能
            </div>
          </div>
          <div class="SCRM-1 flex ja" @click="change2">
            <div
              class="systemTools-2-2"
              style="width: 56px; height: 56px; margin-right: 19px"
            ></div>
            <div :class="[flag == 1 ? 'SCRM-1-1s' : 'SCRM-1-1']">
              企业微信社群运营
            </div>
          </div>
          <div class="SCRM-1 flex ja" @click="change3">
            <div
              class="systemTools-2-3"
              style="width: 56px; height: 56px; margin-right: 19px"
            ></div>
            <div :class="[flag == 2 ? 'SCRM-1-1s' : 'SCRM-1-1']">
              聊天侧边栏功能
            </div>
          </div>
        </div>
        <!-- 功能详细 -->
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="flex ja" style="margin-top: 45px">
                <div class="" style="width: 607px; height: 420px">
                  <div
                    class="huihuaImg"
                    style="width: 56px; height: 56px; margin-top: 45px"
                  ></div>
                  <div class="huihua">会话存档功能</div>
                  <div class="flex ai" style="margin-top: 40px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">
                      全面记录留存微信聊天记录和群聊记录
                    </div>
                  </div>
                  <div class="flex ai" style="margin-top: 24px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">根据关键词等规则设定风险报警</div>
                  </div>
                  <div class="flex ai" style="margin-top: 24px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">降低员工与客户沟通的潜在风险</div>
                  </div>
                  <div class="flex ai" style="margin-top: 24px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">
                      根据聊天内容关键词自动给客户添加标签
                    </div>
                  </div>
                </div>
                <div
                  class="huihuaImg1"
                  style="width: 607px; height: 420px"
                ></div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="flex ja" style="margin-top: 45px">
                <div class="" style="width: 607px; height: 420px">
                  <div
                    class="huihuaImg"
                    style="width: 56px; height: 56px; margin-top: 35px"
                  ></div>
                  <div class="huihua">企业微信社群运营</div>
                  <div class="flex ai" style="margin-top: 20px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">对群进行分组，然后针对性精准营销</div>
                  </div>
                  <div class="flex ai" style="margin-top: 24px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">群数量统计及新增群统计</div>
                  </div>
                  <div class="flex ai" style="margin-top: 24px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">
                      自动拉群，客户所在微信群统计及分析
                    </div>
                  </div>
                  <div class="flex ai" style="margin-top: 24px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">
                      加人数量 退群数量 群内消息数量登统计
                    </div>
                  </div>
                  <div class="flex ai" style="margin-top: 24px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">根据分组登信息对指定群进行群发</div>
                  </div>
                </div>
                <div
                  class="huihuaImg2"
                  style="width: 607px; height: 420px"
                ></div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="flex ja" style="margin-top: 45px">
                <div class="" style="width: 607px; height: 420px">
                  <div
                    class="huihuaImg"
                    style="width: 56px; height: 56px; margin-top: 45px"
                  ></div>
                  <div class="huihua">聊天增强侧边栏功能</div>
                  <div class="flex ai" style="margin-top: 40px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">
                      在聊天侧边栏看客户画像 客户属性 标签 所在群情况
                    </div>
                  </div>
                  <div class="flex ai" style="margin-top: 24px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">
                      在聊天侧边栏看电商等成交记录 会员信息
                    </div>
                  </div>
                  <div class="flex ai" style="margin-top: 24px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">
                      在聊天侧边栏可以看到客户在企业微信里的活动轨迹
                    </div>
                  </div>
                  <div class="flex ai" style="margin-top: 24px">
                    <div class="huihua-1"></div>
                    <div class="huihua-2">
                      文字 文件 图文 图片等各种形式的内容可以一键发给客户
                    </div>
                  </div>
                </div>
                <div
                  class="huihuaImg3"
                  style="width: 607px; height: 420px"
                ></div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>

    <!--  微信裂变营销  -->
    <div style="background: #f8f8f8">
      <div class="systemTools-1">
        <!--  -->
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">企业微信裂变营销</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2">
            开启企业微信时代的私域流量营销管理体系
          </div>
        </div>
        <!--  -->
        <div class="lieBian flex jc-space ai">
          <div class="lieBian1">
            <div class="lieBianImg1"></div>
            <div class="lieBian1-2" style="margin-top: 49px">红包裂变营销</div>
          </div>
          <div class="lieBian1">
            <div class="lieBianImg2"></div>
            <div class="lieBian1-2" style="margin-top: 44px">裂变任务</div>
          </div>
          <div class="lieBian1">
            <div class="lieBianImg3"></div>
            <div class="lieBian1-2" style="margin-top: 45px">抽奖营销</div>
          </div>
          <div class="lieBian1">
            <div class="lieBianImg4"></div>
            <div class="lieBian1-2" style="margin-top: 51px">渠道二维码</div>
          </div>
        </div>
        <div class="lieBian flex jc-space ai">
          <div class="lieBian1">
            <div class="lieBianImg5"></div>
            <div class="lieBian1-2" style="margin-top: 47px">企业微信群发</div>
          </div>
          <div class="lieBian1">
            <div class="lieBianImg6"></div>
            <div class="lieBian1-2" style="margin-top: 46px">朋友圈营销</div>
          </div>
          <div class="lieBian1">
            <div class="lieBianImg7"></div>
            <div class="lieBian1-2" style="margin-top: 47px">
              企业微信群群发
            </div>
          </div>
          <div class="lieBian1">
            <div class="lieBianImg8"></div>
            <div class="lieBian1-2" style="margin-top: 51px">自动回复消息</div>
          </div>
        </div>
        <!--  -->
        <div class="flex ja" style="margin-top: 61px; padding-bottom: 80px">
          <div class="lieBian2">
            <span class="lieBian2-1" @click="changesize">了解更多裂变功能</span>
          </div>
        </div>
      </div>
    </div>

    <!--  行业应用  -->
    <div style="background: #f8f8f8">
      <div class="systemTools-1">
        <!--  -->
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">行业应用</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2">实践验证，数智未来无限可能</div>
        </div>

        <!-- 行业应用 -->

        <div class="industry" style="margin-top: 60px">
          <!-- 行业导航 -->
          <div class="industryLeft" style="">
            <!-- <div :class="[ flag2 == 0 ? 'industryLeft-1s':'industryLeft-1']">应用领域</div>
            <div :class="[ flag2 == 1 ? 'industryLeft-1s':'industryLeft-1']">企业服务</div>
            <div :class="[ flag2 == 2 ? 'industryLeft-1s':'industryLeft-1']">零售行业</div>
            <div :class="[ flag2 == 3 ? 'industryLeft-1s':'industryLeft-1']">教育行业</div>
            <div :class="[ flag2 == 4 ? 'industryLeft-1s':'industryLeft-1']">装修行业</div>
            <div :class="[ flag2 == 5 ? 'industryLeft-1s':'industryLeft-1']">地产公司</div>
            <div :class="[ flag2 == 6 ? 'industryLeft-1s':'industryLeft-1']">金融行业</div>
            <div :class="[ flag2 == 7 ? 'industryLeft-1s':'industryLeft-1']">医美行业</div>
            <div :class="[ flag2 == 8 ? 'industryLeft-1s':'industryLeft-1']">休闲娱乐行业</div> -->
            <div
              v-for="(item, index) in industryList"
              :key="index"
              :class="[flag2 == item.id ? 'industryLeft-1s' : 'industryLeft-1']"
              @mouseover="changeIndustry(item.id)"
            >
              {{ item.titel }}
            </div>
          </div>
          <!-- 行业详情 -->
          <div class="" style="width: 940px; height: 600px">
            <div class="swiper-container2">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <!-- <div class="" style="width:607px;height:420px;">应用领域</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">应用领域</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>随时掌握客户动态信息，做出合理计划与部署</li>
                        <li>获取广告内容打开信息，主动出击不再被动等待</li>
                        <li>快速与客户建立高效联系</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当我们业务员看到自己发出的朋友圈活动广告信息被客户打开十几次的时候，我们就知道这个客户离下单不远了，这时我们会及时给这个客户再发一封详尽的产品介绍，提前做好下一步营销准备，我们很多客户都是这样成交的。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>企业服务</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">企业服务</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>随时掌握客户动态信息，做出合理计划与部署</li>
                        <li>获取广告内容打开信息，主动出击不再被动等待</li>
                        <li>快速与客户建立高效联系</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当我们业务员看到自己发出的朋友圈活动广告信息被客户打开十几次的时候，我们就知道这个客户离下单不远了，这时我们会及时给这个客户再发一封详尽的产品介绍，提前做好下一步营销准备，我们很多客户都是这样成交的。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>零售行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">零售行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>可以帮你解决营销电话过多被封号的问题</li>
                        <li>依据评分优先处理销售线索</li>
                        <li>让业务员和客户微信互动快捷简单</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        方大SCRM给我们公司开展业务提供了新的思路和现实的解决方案，电销的解决方案和微信私域流量的裂变为我们公司获客提供了全新的理念，对我们互联网服务企业真是一款不可多得好产品
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>教育行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">教育行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>借助私域流量，发现更多潜在客户</li>
                        <li>沉淀客户数据资产，高效管理每个客户</li>
                        <li>在微信中持续给客户发送内容种草，促进成交</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当时选择方大SCRM的主要原因是
                        方大SCRM使用起来太方便了。所有客户资料在手机上一目了然，通过这个系统管理员工和客户效率提升不少，员工开发的客户情况和跟进情况我都能通过这个系统来掌握了。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>装修行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">装修行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>全方位勾勒客户画像，快速了解客户个人风格和喜好</li>
                        <li>有效盘活沉睡客户，强化客户管理中的日常维护</li>
                        <li>利用标签与分组，实现客户层级化管理</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        方大SCRM容素材包：提供文章、文件、海报、商品等丰富的内容素材包；可以根据客户的标签给客户发送对应的素材，根据客户的浏览轨迹，分析客户心理，促进客户合作签单
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>地产公司</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">地产公司</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>通过销售漏斗，指导业务过程精细化、数据化</li>
                        <li>方大可以自动筛选，自动分配给销售跟进</li>
                        <li>
                          通过方大提供的CRM组件，帮你沉淀客户数据资产，高效管理每一个客户
                        </li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当我们业务员看到自己发出的朋友圈活动广告信息被客户打开十几次的时候，我们就知道这个客户离下单不远了，这时我们会及时给这个客户再发一封详尽的产品介绍，提前做好下一步营销准备，我们很多客户都是这样成交的。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>金融行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">金融行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>快速准确找到意向客户资源</li>
                        <li>有效管理加盟商信息</li>
                        <li>利用标签与分组，实现加盟代理商层级化管理</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        之前我们公司有多年的客户数据沉淀，使用方大SCRM后可以很好地对这些沉淀的数据进行科学的分组分类、贴标签等操作，从而有针对性地营销，做到多次触达盘活老客户。而针对暂时没有客户资源的新员工来说，公海就是他们优质的资源库，可以自由领取使用跟进，快速成长。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>医美行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">医美行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>给一线销售制定合理的任务计划</li>
                        <li>通过量化指标，查看进度，保证任务的执行落地</li>
                        <li>规范化沉淀客户资源</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        根据销售和客户的互动行为，以及客户在内容中留下的行为轨迹，智能计算得出互动指数。销售可以结合互动指数，对不同层级的客户使用针对策略去跟进，促使销售转化率达到目标或预期
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>休闲娱乐行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">休闲娱乐行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>快速与客户建立高效联系潜能</li>
                        <li>快速准确找到意向客户资源利润</li>
                        <li>借助人工智能实现个性化推荐潜客</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        之前我们公司有多年的客户数据沉淀，使用方大SCRM后可以很好地对这些沉淀的数据进行科学的分组分类、贴标签等操作，从而有针对性地营销，做到多次触达盘活老客户。而针对暂时没有客户资源的新员工来说，公海就是他们优质的资源库，可以自由领取使用跟进，快速成长。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="swiper-pagination"></div> -->
              <!-- <div class="swiper-scrollbar"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-top></footer-top>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import footerTop from "@/components/footerTop.vue";
import Footer from "@/components/footer.vue";
import { Swiper, Navigation, Pagination, Autoplay, Scrollbar } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar]);
import eventBus from '../config/eventBus.js'
export default {
  name: "",
  components: {
    HeadNav,
    footerTop,
    Footer,
  },
  data() {
    return {
      industryList: [
        {
          id: 0,
          titel: "应用领域",
        },
        {
          id: 1,
          titel: "企业服务",
        },
        {
          id: 2,
          titel: "零售行业",
        },
        {
          id: 3,
          titel: "教育行业",
        },
        {
          id: 4,
          titel: "装修行业",
        },
        {
          id: 5,
          titel: "地产公司",
        },
        {
          id: 6,
          titel: "金融行业",
        },
        {
          id: 7,
          titel: "医美行业",
        },
        {
          id: 8,
          titel: "休闲娱乐行业",
        },
      ],
      flag: 0,
      flag2: 0,
      Myswiper: null,
      Myswiper2: null,
    };
  },
  mounted() {
    var that = this;
    that.Myswiper = new Swiper(".swiper-container", {
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
      },
      on: {
        transitionEnd: function () {
          that.flag = this.activeIndex;
        },
      },
    });
    that.Myswiper2 = new Swiper(".swiper-container2", {
      // autoplay: false,
      // direction : 'vertical',

      direction: "vertical",
      navigation: false,
      // autoplay: {
      //   delay: 3000,
      //   stopOnLastSlide: false,
      //   disableOnInteraction: false,
      // },
      // pagination: {
      //     el: '.swiper-pagination',
      // },
      on: {
        transitionEnd: function () {
          that.flag2 = this.activeIndex;
          // console.log(that.flag2);
        },
      },
    });
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    change1() {
      this.flag = 0;
      this.Myswiper.slideTo(0, 1000, false);
    },
    change2() {
      this.flag = 1;
      this.Myswiper.slideTo(1, 1000, false);
    },
    change3() {
      this.flag = 2;
      this.Myswiper.slideTo(2, 1000, false);
    },
    changeIndustry(e) {
      // console.log(e,'654')
      this.flag2 = e;
      this.Myswiper2.slideTo(e, 1000, false);
    },
    changesize(){
        eventBus.$emit('add',true)
    }
  },
  watch: {
    // $route(to,form) {
    //   console.log(to)
    //   console.log(form)
    //   this.$router.go(0)
    // }
  },
};
</script>

<style src="@/style/QiXunSCRM.css" scoped></style>
<style lang="scss" scoped>
.swiper-container {
  width: 100%;
  height: 530px;
  margin-top: 20px;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
    }
  }
}
.swiper-container2 {
  width: 960px;
  height: 600px;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/homepage",
  },
  // {
  //   path: "/about",
  //   name: "About",
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  //02首页
  {
    path: "/homepage",
    name: "Homepage",
    component: () => import("../views/Homepage.vue"),
  },
  //03企讯SCRM
  {
    path: "/qiXunScrm",
    name: "QiXunScrm",
    component: () => import("../views/QiXunSCRM.vue"),
  },
  //04产品中心
  {
    path: "/productcenter",
    name: "ProductCenter",
    component: () => import("../views/ProductCenter.vue"),
  },
  //05企讯宝
  {
    path: "/qiXunBao",
    name: "QiXunBao",
    component: () => import("../views/QiXunBao.vue"),
  },
  //06企讯云Ai
  {
    path: "/qiXunCloudAi",
    name: "QiXunCloudAi",
    component: () => import("../views/QiXunCloudAi.vue"),
  },
  //07企讯云+
  {
    path: "/qiXunCloud+",
    name: "QiXunCloud+",
    component: () => import("../views/QiXunCloud+.vue"),
  },
  //08解决方案
  {
    path: "/solution",
    name: "Solution",
    component: () => import("../views/Solution.vue"),
  },
  //09解决方案--教育行业详情
  {
    path: "/solutionDetails",
    name: "SolutionDetails",
    component: () => import("../views/SolutionDetails.vue"),
  },
  //09解决方案--金融行业详情
  {
    path: "/automobileIndustry",
    name: "AutomobileIndustry",
    component: () => import("../views/AutomobileIndustry.vue"),
  },
  //09解决方案--房产行业详情
  {
    path: "/realEstateIndustry",
    name: "RealEstateIndustry",
    component: () => import("../views/RealEstateIndustry.vue"),
  },
  //16客户案例
  {
    path: "/customercase",
    name: "CustomerCase",
    component: () => import("../views/CustomerCase.vue"),
  },
  //17下载中心
  {
    path: "/downloadCenter",
    name: "DownloadCenter",
    component: () => import("../views/DownloadCenter.vue"),
  },
  //18关于我们-简介
  {
    path: "/aboutUsIntroduce",
    name: "AboutUsIntroduce",
    component: () => import("../views/AboutUsIntroduce.vue"),
  },
  //19关于我们-加入我们
  {
    path: "/aboutUsJoinUs",
    name: "AboutUsJoinUs",
    component: () => import("../views/AboutUsJoinUs.vue"),
  },
  //20会员登录
  {
    path: "/memberlogin",
    name: "MemberLogin",
    component: () => import("../views/MemberLogin.vue"),
  },
  //21新闻资讯-企业动态
  {
    path: "/enterpriseDynamics",
    name: "EnterpriseDynamics",
    component: () => import("../views/EnterpriseDynamics.vue"),
  },
  //22客户案例详情
  {
    path: "/customerCaseDetails",
    name: "CustomerCaseDetails",
    component: () => import("../views/CustomerCaseDetails.vue"),
  },
  //24客户服务
  {
    path: "/customerService",
    name: "CustomerService",
    component: () => import("../views/CustomerService.vue"),
  },
  //26系统接入合作
  {
    path: "/systems",
    name: "Systems",
    component: () => import("../views/Systems.vue"),
  },
  //新闻详情
  // {
  //   path: "/systems",
  //   name: "Systems",
  //   component: () => import("../views/Systems.vue"),
  // },
  //服务支持
  {
    path: "/servicesupport",
    name: "ServiceSupport",
    component: () => import("../views/ServiceSupport.vue"),
  },
  {
    path: "*",
    redirect: "/homepage",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <!-- 产品中心 -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">方大私有云</div>
            <!-- <div class="backgroundImg2">
              云服务器 ECS（Elastic Compute
              Service）是一种弹性可伸缩的云计算服务，助您降低 IT
              成本，提升运维效率，使您更专注于核心业务创新。专业的售前技术支持，协助您选择最合适配置方案，详询客服。
            </div> -->
            <div class="backgroundImg2">
              <div>方大拥有500台+的自建私有云机房</div>
              <div style="margin-top:8px;">为您数据安全与核心业务保驾护航</div>           
            </div>
            <!-- <div class="backgroundImg3">
              <span class="backgroundImg3-1" @click="changesize">立即购买</span>
            </div> -->
          </div>
        </div>
      </header>
    </div>

    <!--  -->
    <div :class="[flags ? 'navigation' : 'navigations']">
      <div class="flex ai" style="width: 1200px; height: 60px; margin: 0 auto">
        <!-- <div
          :class="[changeFlag == 1 ? 'navigation1s' : 'navigation1']"
          @click="change1"
        >
          产品功能
        </div> -->
        <div
          :class="[changeFlag == 2 ? 'navigation1s' : 'navigation1']"
          @click="change2"
        >
          优势
        </div>
        <div
          :class="[changeFlag == 3 ? 'navigation1s' : 'navigation1']"
          @click="change3"
        >
          应用场景
        </div>
      </div>
    </div>

    <!--  产品功能    -->
    <!-- <div :class="[flags ? 'systemTools' : 'systemToolss']" style="">

      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">产品功能</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div> -->
      <!-- 功能 -->
      <!-- <div class="function" style="height: 327px">
        <div class="function1" style="padding-top: 60px">
          丰富的实例类型和多种存储选择
        </div>
        <div class="function2">
          <div class="function2-1">
            面向各类企业应用场景，云服务器ECS将提供超过30款高性能规格族供您选择。按您的实际业务场景可选择不同配置实例搭配1到17块不同容量的存储磁盘。
          </div>
        </div>
        <div class="function3">
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">基础版</div>
              <div class="function3-1-2-2">
                单机实例，最高性价比，数据可靠性高达9个9，同时具备故障转移能力，可保障您的业务持续运行，支持
                SQL Server Web版本。
              </div>
            </div>
          </div>
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">高可用版</div>
              <div class="function3-1-2-2">
                主备架构双机实例，主备节点位于不同服务器，实现秒级高可用，支持链接服务器、分布式事务、变更数据捕获等高级特性，涵盖
                SQL Server 标准版和企业版。
              </div>
            </div>
          </div>
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">多种存储选择</div>
              <div class="function3-1-2-2">
                按您的实际业务场景可选择不同配置实例，搭配1到17块不同容量的存储磁盘。云盘、本地盘提供给您多样选择的同时，云盘将提供给您最高99.9999999%的可靠性。
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!--  VPC专有网络  -->
      <!-- <div class="function" style="height: 336px">
        <div class="function1" style="padding-top: 50px">VPC专有网络</div>
        <div class="function2">
          <div class="function2-1">
            基于阿里云构建的一个隔离的网络环境，专有网络之间逻辑上彻底隔离，只能通过对外映射的IP（弹性公网IP和NAT
            IP）互联。由于使用隧道封装技术对云服务器的IP报文进行封装，所以云服务器的数据链路层（二层MAC地址）信息不会进入物理网络，实现了不同云服务器间二层网络隔离，因此也实现了不同专有网络间二层网络隔离。专有网络内的ECS使用安全组防火墙进行三层网络访问控制。
          </div>
        </div>
        <div class="function3">
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">访问控制</div>
              <div class="function3-1-2-2">
                灵活的访问控制规则。 满足政务、金融的安全隔离规范。
              </div>
            </div>
          </div>
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">软件定义网络</div>
              <div class="function3-1-2-2">
                按需配置网络设置，软件定义网络。管理操作实时生效。
              </div>
            </div>
          </div>
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">丰富的网络连接方式</div>
              <div class="function3-1-2-2">支持软件VPN；支持专线连接。</div>
            </div>
          </div>
        </div>
      </div> -->
      <!--  快照与多种镜像类型  -->
      <!-- <div class="function" style="height: 370px">
        <div class="function1" style="padding-top: 50px">
          快照与多种镜像类型
        </div>
        <div class="function2">
          <div class="function2-1">
            快照与多种镜像类型，支持您的业务快速部署。
          </div>
        </div>
        <div class="function3">
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">快照安全系数更高</div>
              <div class="function3-1-2-2">
                每个云盘有1256个快照额度，为客户数据提供更长的保护周期和更细的保护粒度
              </div>
            </div>
          </div>
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">自动快照策略</div>
              <div class="function3-1-2-2">
                实现无人值守的自动化数据备份，减轻运维部门工作负担。
              </div>
            </div>
          </div>
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">快照灵活易用</div>
              <div class="function3-1-2-2">
                性能影响小，用户业务无感知，随时支持数据快照备份。
              </div>
            </div>
          </div>
        </div>
        <div class="function3">
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">镜像种类丰富</div>
              <div class="function3-1-2-2">
                公共镜像、镜像市场、自定义镜像满足各类型用户对于应用环境的快速部署、灵活管理的需求。
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!--  多种付费和存储选择  -->
      <!-- <div class="function" style="height: 484px">
        <div class="function1" style="padding-top: 50px">
          多种付费和存储选择
        </div>
        <div class="function2">
          <div class="function2-1">
            通过包年包月、按量付费、节省计划、预留实例券和抢占式5种付费模式分别满足长周期低成本以及周期高弹性的计算要求。
          </div>
        </div>
        <div class="function3">
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">节省计划</div>
              <div class="function3-1-2-2">
                适合用户在一定时间段内（1年或3年）有稳定使用云资源的场景（以元/小时为单位衡量）。在购买节省计划后，开通计划下的ECS、ECI等按量资源，每小时可享受计划折扣权益，每小时账单将自动进行抵扣。
              </div>
            </div>
          </div>
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">包年包月购买</div>
              <div class="function3-1-2-2">
                适合长期稳定的业务，购买周期越长，折扣越高，最高可享受5年3折。
              </div>
            </div>
          </div>
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">按量付费购买</div>
              <div class="function3-1-2-2">
                紧贴业务需求购买资源的付费方式，秒级计费，用多少花多少。
              </div>
            </div>
          </div>
        </div>
        <div class="function3">
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">预留实例券</div>
              <div class="function3-1-2-2">
                一种抵扣券，适合需要兼顾灵活性和成本的业务，或者需要预留实例资源的业务。搭配按量付费实例使用，可以抵扣掉按量付费实例计算部分的账单。相比直接使用按量付费实例，成本最大可以降低79%。
              </div>
            </div>
          </div>
          <div class="function3-1">
            <div class="function3-1-1"></div>
            <div class="function3-1-2">
              <div class="function3-1-2-1">抢占式实例</div>
              <div class="function3-1-2-2">
                适合周期性离线计算场景，可使用远低于原价的成本获得资源，最低原价一折售卖，但会根据供需变化或者竞价价格而被释放。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!--  产品优势  -->
    <div class="systemTools" style="height: 920px; background: #f8f8f8">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">优势</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>
      <!-- 优势 -->
      <div class="systemTools-1" style="margin-top: 60px">
        <div class="product">
          <div class="product1">
            <div class="product-Img1"></div>
            <div class="product-size1">稳定</div>
            <div class="product-size2">
              实例稳定性、可靠性达 99.9%,可实现自动宕机迁移、快照备份
            </div>
          </div>
          <div class="product1">
            <div class="product-Img2"></div>
            <div class="product-size1">弹性</div>
            <div class="product-size2">
              支持单机96核/256g内存，具有弹性的扩容能力，实例与带宽均可随时升降配，云盘可扩容
            </div>
          </div>
          <div class="product1">
            <div class="product-Img3"></div>
            <div class="product-size1">高安全</div>
            <div class="product-size2">
              融合系统多盘调用，系统数据实时备份；多运营商宽带自由切换
            </div>
          </div>
        </div>
        <div class="product" style="margin-top: 30px">
          <div class="product1">
            <div class="product-Img4"></div>
            <div class="product-size1">高性能</div>
            <div class="product-size2">
              单实例可达96vCPU，内存256g，单实例性能可达500万PPS网络首发包，40Gbps带宽
            </div>
          </div>
          <div class="product1">
            <div class="product-Img5"></div>
            <div class="product-size1">易用性</div>
            <div class="product-size2">
              丰富的操作系统和应用软件，通过镜像可一键简单部署，同一镜像可在多台
              ECS 中快速复制环境，轻松扩展
            </div>
          </div>
          <div class="product1">
            <div class="product-Img6"></div>
            <div class="product-size1">可拓展性</div>
            <div class="product-size2">
              各种丰富的云产品无缝衔接，可持续为业务发展提供完整的计算、存储、安全等解决方案
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用场景 -->
    <div>
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">应用场景</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <!-- 场景 -->
      <div class="systemTools-1">
        <div class="systemTools1-1">
          <!-- <div :class="[ flag == 0 ? 'scenes':'scene']" @click="changeScene">
            <span>
              通用Web应用
            </span>
          </div>
          <div :class="[ flag == 1 ? 'scenes':'scene']">
            <span>
              在线游戏
            </span>
          </div>
          <div :class="[ flag == 2 ? 'scenes':'scene']">
            <span>
              大数据分析
            </span>
          </div>
          <div :class="[ flag == 3 ? 'scenes':'scene']">
            <span>
              深度学习
            </span>
          </div> -->
          <div
            v-for="(item, index) in sceneList"
            :key="index"
            class="scene"
            @click="changeScene(item.id)"
          >
          <!-- <div
            v-for="(item, index) in sceneList"
            :key="index"
            :class="[flag == item.id ? 'scenes' : 'scene']"
            @click="changeScene(item.id)"
          > -->
            <span>{{ item.title }}</span>
          </div>
        </div>
        <div class="Cloud3"></div>
      </div>

      <!-- <div class="systemTools-1" style="height: 700px">
        <div class="scene1" v-if="flag == 0">
          <div class="sceneLeft sceneLeft1"></div>
          <div class="sceneRight">
            <div class="sceneRight1">通用Web应用</div>
            <div class="sceneRight2">
              大部分Web应用使用的架构，阿里云推荐C/G/R系列服务器，兼顾高效搭建使用及高性能处理能力
            </div>

            <div class="sceneRight3">能够解决</div>
            <div class="sceneRight4">
              <div class="sceneRight4Flex">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  CDN网络为Web应用提供内容分发服务，保证最优用户体验与成本节约
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  内容缓存在OSS存储中，提供高可靠性、低成本的存储容量
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  负载均衡处理HTTP请求，并将流量分发到主业务可用区
                  前端和应用服务器部署在ECS 实例上，SLB可以做到负载均衡
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  ESS弹性伸缩按需业务创建或释放资源，使您能够获得最优的业务性能和成本支出
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  部署在多可用区RDS上的数据库保证业务的容灾性能
                </div>
              </div>
            </div>
            <div class="sceneRight3">推荐搭配使用</div>
            <div class="sceneRight5">
              <div class="sceneRight5-1">
                <div class="sceneRight5Img"></div>
                <div>CDN</div>
              </div>
              <div class="sceneRight5-1" style="margin-left: 20px">
                <div class="sceneRight5Img"></div>
                <div>CDN</div>
              </div>
            </div>
          </div>
        </div>
        <div class="scene1" v-if="flag == 1">
          <div class="sceneLeft sceneLeft2"></div>
          <div class="sceneRight">
            <div class="sceneRight1">在线游戏</div>
            <div class="sceneRight2">
              高并发、瞬时计算量大的场景，阿里云推荐高主频及GPU服务器实现高计算性能与高图像渲染性能的需求
            </div>

            <div class="sceneRight3">能够解决</div>
            <div class="sceneRight4">
              <div class="sceneRight4Flex">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  负载均衡处理HTTP请求，并将流量分发到指定的游戏服集群
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  连接服、游戏服、缓存服等通过ESS弹性伸缩按需创建或释放资源
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  部署在多可用区RDS上的数据库保证业务的容灾性能
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  ESS弹性伸缩按需业务创建或释放资源，使您能够获得最优的业务性能和成本支出
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  部署在多可用区RDS上的数据库保证业务的容灾性能
                </div>
              </div>
            </div>
            <div class="sceneRight3">推荐搭配使用</div>
            <div class="sceneRight5">
              <div class="sceneRight5-1">
                <div class="sceneRight5Img"></div>
                <div>CDN</div>
              </div>
              <div class="sceneRight5-1" style="margin-left: 20px">
                <div class="sceneRight5Img"></div>
                <div>CDN</div>
              </div>
            </div>
          </div>
        </div>
        <div class="scene1" v-if="flag == 2">
          <div class="sceneLeft sceneLeft3"></div>
          <div class="sceneRight">
            <div class="sceneRight1">大数据分析</div>
            <div class="sceneRight2">
              对于频繁对存储读取的大数据应用场景，阿里云推荐大数据实例及本地盘实例，主从节点皆有性能优异表现
            </div>

            <div class="sceneRight3">能够解决</div>
            <div class="sceneRight4">
              <div class="sceneRight4Flex">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  业务系统产生日志等数据传输到Hadoop大数据存储进行分析；或用户大数据存在OSS对象存储，并加载到Hadoop大数据系统中进行分析
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  基于ECS大数据实例构建高性价比、海量可扩展的云端Hadoop系统
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  分析结果可以存放在MySQL或NoSQL(HBase/MongoDB/Redis)等数据库，便于用户查询
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">大数据 Bi 及展示</div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  部署在多可用区RDS上的数据库保证业务的容灾性能
                </div>
              </div>
            </div>
            <div class="sceneRight3">推荐搭配使用</div>
            <div class="sceneRight5">
              <div class="sceneRight5-1">
                <div class="sceneRight5Img"></div>
                <div>CDN</div>
              </div>
              <div class="sceneRight5-1" style="margin-left: 20px">
                <div class="sceneRight5Img"></div>
                <div>CDN</div>
              </div>
            </div>
          </div>
        </div>
        <div class="scene1" v-if="flag == 3">
          <div class="sceneLeft sceneLeft4"></div>
          <div class="sceneRight">
            <div class="sceneRight1">深度学习</div>
            <div class="sceneRight2">
              对于持续且大量的人工神经网络计算的深度学习场景，阿里云推荐GPU实例及AMD实例，不但性能表现卓越，同时大量节省成本
            </div>

            <div class="sceneRight3">能够解决</div>
            <div class="sceneRight4">
              <div class="sceneRight4Flex">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  在数据层面可以与OSS对象存储、NAS文件存储、云盘等打通，满足深度学习海量训练数据的低成本存储和访问要求
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">通过EMR服务进行数据的预处理</div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  通过云监控服务进行GPU资源的监控与告警
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  通过ECS、负载均衡、弹性伸缩、资源编排等快速在云端搭建完整AI深度学习业务系统
                </div>
              </div>
              <div class="sceneRight4Flex" style="margin-top: 24px">
                <div class="sceneRight4-1"></div>
                <div class="sceneRight4-2">
                  部署在多可用区RDS上的数据库保证业务的容灾性能
                </div>
              </div>
            </div>
            <div class="sceneRight3">推荐搭配使用</div>
            <div class="sceneRight5">
              <div class="sceneRight5-1">
                <div class="sceneRight5Img"></div>
                <div>CDN</div>
              </div>
              <div class="sceneRight5-1" style="margin-left: 20px">
                <div class="sceneRight5Img"></div>
                <div>CDN</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <footer-top style="margin-top: 80px"></footer-top>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import Footer from "@/components/footer.vue";
import footerTop from "@/components/footerTop.vue";
import eventBus from '../config/eventBus.js'
export default {
  name: "",
  components: {
    HeadNav,
    Footer,
    footerTop,
  },
  data() {
    return {
      flag: 0,
      sceneList: [
        {
          id: 0,
          title: "通用Web应用",
        },
        {
          id: 1,
          title: "在线游戏",
        },
        {
          id: 2,
          title: "大数据分析",
        },
        {
          id: 3,
          title: "深度学习",
        },
      ],
      scrollTops: "",
      flags: true, //选择栏标记
      changeFlag: 1, //选择标记
    };
  },
  mounted() {
    // this.timeChange();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    changeScene(e) {
      this.flag = e;
    },
    // change1() {
    //   window.scrollTo(0, 423);
    // },
    change2() {
      window.scrollTo(0, 421);
    },
    change3() {
      window.scrollTo(0, 1279);
    },
    handleScroll() {
      // this.scrollTops = document.getElementById("navigation").scrollTop
      this.scrollTops =
        document.body.scrollTop || document.documentElement.scrollTop;
      // console.log(this.scrollTops);
      // if (this.scrollTops < 2110) this.changeFlag = 1;
      if (this.scrollTops < 1279)
        this.changeFlag = 2;
      if (this.scrollTops >= 1279) this.changeFlag = 3;
      if (this.scrollTops > 421) {
        this.flags = false;
      } else {
        this.flags = true;
      }
    },
    changesize(){
      eventBus.$emit('add',true)
    }
  },
};
</script>

<style src="@/style/ProductCenter.css" scoped></style>
<style lang="scss" scoped></style>

<template>
  <!-- 关于我们-加入我们   -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">加入我们</div>
            <div class="backgroundImg2">JOIN US</div>
          </div>
        </div>
      </header>
    </div>

    <!-- 招贤纳士 -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">方大招贤纳士</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <!--  -->
      <div class="systemTools-1">
        <div class="recruit1">
          成都方大软件科技有限责任公司成立于 2006
          年12月，公司致力于办公外呼系统和外呼机器人，和三大运营
          商强强联手，拥有业内优质资源。内部有完整的市场开发、市场营销、售后服务团队。公司自成立以来帮助上百万家企业采集精准客户信息，成功实现让客户与商家沟通零距离！公司在全国拥有百万用户，现着手打造成都样板市场，欢迎您加入互联网软件大军，并且挑战成为富有激情的精英
          !
        </div>
        <div class="recruit2">应聘方式：</div>
        <div class="recruit3">
          <div>1.您可以通过官网直接申请&nbsp;&nbsp;邮箱：2853636504@qq.com &nbsp;&nbsp;联系电话: 028-61360285</div>
          <div>2.也可以通过合作渠道进行投递，合作渠道如下：</div>
        </div>
        <div class="recruit4">
          <a href="https://www.zhaopin.com/companydetail/CC337212814.htm" target="_blank"><div class="recruit4-1"></div></a>
          <a href="https://www.zhipin.com/gongsir/de88a4487212deb01HR50tm-FQ~~.html?ka=company-jobs" target="_blank"><div class="recruit4-2"></div></a>
          <a href="https://www.lagou.com/gongsi/v1/j/fba7aabc654829ec16a879b74002133687c81d67ba9a6c33.html" target="_blank"><div class="recruit4-3"></div></a>
        </div>
      </div>
    </div>

    <!-- 方大团建 -->
    <div>
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">方大团建</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
        <div style="margin-top:40px;">
          <div class="flex jc" style="">
            <!-- 1 -->
            <!-- <div class="flex" style="border:1px solid blue;">
              <div class="construction1" style="border:1px solid black;"></div>
              <div style="">
                <div class="flex" style="border:1px solid black;">
                  <div class="construction2" style="border:1px solid black;"></div>
                  <div class="construction3" style="border:1px solid black;"></div>
                </div>
                <div style="height:200px;border:1px solid black;">
                  <div class="construction4" style="width:40%;height:200px;border:1px solid black;"></div>
                  <div class="construction5"></div>
                </div>
              </div>
            </div> -->

            <!-- 2 -->
            <!-- <div class="flex">
              <div class="construction6" style="flex:1;height:200px; border:1px solid yellow;"></div>
              <div class="construction7" style="flex:1;height:200px; border:1px solid yellow;"></div>
              <div class="construction8" style="flex:1;height:200px; border:1px solid yellow;"></div>
              <div class="construction9" style="flex:1;height:200px; border:1px solid yellow;"></div>
            </div> -->


            <div class="flex" style=" flex:1;justify-content:flex-end;">
              <div class="" style="margin-right:10px;margin-top:160px;">
                <div class="construction1" style=""></div>
                <div class="construction2" style="margin-left: 53px;margin-top:10px;"></div>
              </div>
              <div style="margin: 10px;">
                <div class="construction3" style=""></div>
                <div class="construction4" style="margin:5px 0;"></div>
                <div class="construction5" style=""></div>
              </div>
            </div>


            <div style="flex:1;">
              <div class="construction6" style="margin:10px 0 5px 10px;"></div>
              <div class="flex">
                <div>
                  <div class="construction7" style=""></div>
                  <div class="construction8" style=""></div>
                </div>
                <div class="construction9" style=""></div>
              </div>
              <div class="construction10" style=""></div>
            </div>


          </div>
        </div>
      </div>
    </div>

    <!-- 正在招聘 -->
    <div>
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">正在招聘</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>

        <div class="flex" style="margin-top:40px;">
          <div :class="flag ? 'changeing': '' " class="sale" @click="changeSale">销售部</div>
          <div :class="flag ? '': 'changeing' " class="technology" @click="changeTechnology">技术支撑部</div>
        </div>

        <div v-if="flag" style="margin-bottom: 60px;">
          <div class="" style="height: 80px;border:1px solid #DCDFE6;border-radius: 10px;margin-top: 40px;">
            <div style="line-height: 80px;margin-left: 30px;font-size: 18px;">
              软件顾问
            </div>
          </div>

          <div class="position1">
            <div class="position1-1">软件顾问</div>
            <div class="flex position1-2">
              <div style="flex:1;">
                <div>工作地点</div>
                <div>成都</div>
              </div>
              <div style="flex:1;">
                <div>薪资待遇</div>
                <div>面议</div>
              </div>
              <div style="flex:1;">
                <div>职位类型</div>
                <div>全职</div>
              </div>
              <div style="flex:1;">
                <div>工作经验</div>
                <div>1-3年</div>
              </div>
            </div>

            <div class="position1-3">
              <div class="position1-3_1">岗位画像: </div>
              <div>1、了解客户需求，根据公司提供的帮助挖掘客户资源；</div>
              <div>2、对我门的企业用户进行产品介绍、产品测试；</div>
              <div>3、负责开发意向客户和自身未成交客户的信息管理、跟进工作；</div>
              <div>4、熟练学握产品的性能、参数、适用性、产品卖点等基础信息；完成销售计划；</div>
              <div>5、对自己所属区域内客户开发、成交、付款、以及客户交付给技术等问题负责；</div>
              <div>6、对公司分配的客户资源进行开发跟进达成成交；</div>
              <div>7、做好所有客户信息管理，定时定期回访跟进提高客户黏性；</div>
              <div>8、在领导的指导下完成入职学习部分，前期领导会帮你谈单，直到你能独立谈单为止；</div>
              <div>9、我们的业务不是一次性销售，客户续费率达到70%以上，客户连续使用年限平均2-3年，每一笔续费都是你的业绩。</div>
            </div>

            <div class="position1-3">
              <div class="position1-3_1">岗位要求：</div>
              <div>1、有较强的表达能力，熟悉与客户沟通的方法与技巧；</div>
              <div>2、有较强的学习能力；</div>
              <div>3、懂得基本的礼仪；</div>
              <div>4、有一定销售经验；</div>
            </div>
            
            <div class="flex jc" style="margin-top: 20px;">
              <div style="width: 120px;height: 50px;border: 1px solid #FE9345;border-radius: 6px;text-align: center;line-height: 50px;"><a href="mailto:2853636504@qq.com" style="text-decoration:none;"><span style="color: #FE9345;">我要应聘</span></a></div>
            </div>
          </div>
        </div>
        
        
        <div v-if="!flag" style="margin-bottom: 60px;">
          <div class="" style="height: 80px;margin-top: 40px;border:1px solid #DCDFE6;border-radius: 10px;">
            <div style="line-height: 80px;margin-left: 30px;">
              软件售后客服
            </div>
          </div>

          <div class="position1">
            <div class="position1-1">软件售后客服</div>
            <div class="flex position1-2">
              <div style="flex:1;">
                <div>工作地点</div>
                <div>成都</div>
              </div>
              <div style="flex:1;">
                <div>薪资待遇</div>
                <div>面议</div>
              </div>
              <div style="flex:1;">
                <div>职位类型</div>
                <div>全职</div>
              </div>
              <div style="flex:1;">
                <div>工作经验</div>
                <div>1-3年</div>
              </div>
            </div>

            <div class="position1-3">
              <div class="position1-3_1">任职要求：</div>
              <div>1、网络工程专业和计算机相关专业优先，有售后客服经验优先；</div>
              <div>2、能熟练运用办公软件；</div>
              <div>3、具有较强的执行力、应变能力，语言表达与沟通能力；</div>
              <div>4、有责任心；</div>
              <div>5、普通话必须标准，口齿伶俐，懂得消费者心理，语言表达能力强，擅于沟通;</div>
              <div>6、性格好，有耐心，身体健康，能够长期稳定的干好工作;</div>
              <div>7、有团队合作精神；</div>
            </div>

            <div class="position1-3">
              <div class="position1-3_1">岗位职责：</div>
              <div>1、接收客户售后咨询，为客户指导系统操作方法及其他问题；</div>
              <div>2、了解客户服务需求信息，进行有效跟踪，售后指导和服务工作；</div>
              <div>3、维护与客户关系；</div>
              <div>4、与相关联部门紧密配合，协调沟通。</div>
              <div>5、无销售性质，纯售后，网络在线客服，不打电话！</div>
            </div>
            
            <div class="flex jc" style="margin-top: 20px;">
              <div style="width: 120px;height: 50px;border: 1px solid #FE9345;border-radius: 6px;text-align: center;line-height: 50px;"><a href="mailto:2853636504@qq.com" style="text-decoration:none;"><span style="color: #FE9345;">我要应聘</span></a></div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "",
  components: {
    HeadNav,
    Footer,
  },
  data() {
    return {
      flag: true,
    };
  },
  mounted() {
    // this.timeChange();
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    changeSale() {
      this.flag = true
    },
    changeTechnology() {
      this.flag = false
    }
  },
};
</script>

<style src="@/style/AboutUsJoinUs.css" scoped></style>
<style lang="scss" scoped></style>

<template>
  <!-- 企讯云Ai -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">企讯云AI</div>
            <div class="backgroundImg2">
              基于语音识别、语音合成、自然语言理解等技术应用，赋予产品“听得慌、说得清”式的智能人机交互体验，为企业在多种实际应用场景下，实现精准识别用户意图，自动应答，自助办理业务，用科技解放人力，提高企业运营效率、降低人工成本。
            </div>
            <div class="backgroundImg3">
              <span class="backgroundImg3-1" @click="changesize">立即购买</span>
            </div>
          </div>
        </div>
      </header>
    </div>

    <!-- 产品功能 -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">产品功能</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>
      <!-- 功能 -->
      <div class="productFunction" style="margin: 60px auto">
        <div class="productFunction1">
          <div class="productFunctionImg1"></div>
          <div class="productFunction1-1" style="width: 88px">多轮会话</div>
          <div class="productFunction1-2">
            基于真人语音和语音合成的人机对话，通过多次交互深刻理解客户意图，通过多轮会话可以解决例如外呼营销、回访等全场景业务问题
          </div>
        </div>
        <div class="productFunction1">
          <div class="productFunctionImg2"></div>
          <div class="productFunction1-1" style="width: 89px">打断插话</div>
          <div class="productFunction1-2" style="width: 237px">
            在机器人说话时客户可以随时打断或插话，机器人可以根据客户的插话重新理解客户意思判断分析客户意图，使对话自然有序流畅进行
          </div>
        </div>
        <div class="productFunction1">
          <div class="productFunctionImg3"></div>
          <div class="productFunction1-1" style="width: 110px">自定义话术</div>
          <div class="productFunction1-2" style="width: 237px">
            方大机器人使用方便，维护简单便捷，可根据客户实际情况方便快捷设置业务话术、训练机器人，提高效率，节约成本
          </div>
        </div>
        <div class="productFunction1">
          <div class="productFunctionImg4"></div>
          <div class="productFunction1-1" style="width: 134px">
            批量自动拨打
          </div>
          <div class="productFunction1-2" style="width: 237px">
            方大机器人采用稳定的预测式呼叫中心外呼系统，可以同时批量外呼，提高效率，不设置并发数上限，支持无限并发
          </div>
        </div>
      </div>
      <div class="productFunction" style="margin: 42px auto 80px">
        <div class="productFunction1">
          <div class="productFunctionImg5"></div>
          <div class="productFunction1-1" style="width: 110px">自动转人工</div>
          <div class="productFunction1-2" style="width: 237px">
            对话过程中，如果机器人未能识别出客户应答三次（可设定次数）则自动转人工座席，话务随路数据也相应转移到人工座席
          </div>
        </div>
        <div class="productFunction1">
          <div class="productFunctionImg6"></div>
          <div class="productFunction1-1" style="width: 132px">
            自动分类筛选
          </div>
          <div class="productFunction1-2" style="width: 237px">
            精准识别客户意向，智能标签分类，助力企业轻松实现精准营销、自动聚类、精确报表等，客服坐席人员即可轻松应对
          </div>
        </div>
        <div class="productFunction1">
          <div class="productFunctionImg7"></div>
          <div class="productFunction1-1" style="width: 179px">
            全程录音自动转写
          </div>
          <div class="productFunction1-2" style="width: 237px">
            高效整理客户资料，把非结构化数据转换为结构化数据，有利于客户做质检、大数据分析及机器人的优化和学习
          </div>
        </div>
        <div class="productFunction1">
          <div class="productFunctionImg8"></div>
          <div class="productFunction1-1" style="width: 135px">
            统计分析报表
          </div>
          <div class="productFunction1-2" style="width: 237px">
            方大机器人支持智能提取数据，自动分类，全面高效地将客户信息进行分类整理，形成报表，给管理人员提供实时决策依据
          </div>
        </div>
      </div>
    </div>

    <!-- 产品优势 -->
    <div style="width: 100%; height: 703px; background: #f8f8f8">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">产品优势</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>
      <!-- 优势 -->
      <div class="productAdvantages" style="margin: 0 auto">
        <div class="productAdvantages1">
          <div class="productAdvantagesImg1"></div>
          <div class="productAdvantages1-1" style="width: 89px">反应快速</div>
          <div class="productAdvantages1-2" style="width: 160px">
            响应时间快达1.5~2秒 完全媲美真人体验
          </div>
        </div>
        <div class="productAdvantages1">
          <div class="productAdvantagesImg2"></div>
          <div class="productAdvantages1-1" style="width: 89px">支持打断</div>
          <div class="productAdvantages1-2" style="width: 217px">
            对话过程中，客户打断则自动 停止当前对话，并重新识别
          </div>
        </div>
        <div class="productAdvantages1">
          <div class="productAdvantagesImg3"></div>
          <div class="productAdvantages1-1" style="width: 88px">多轮会话</div>
          <div class="productAdvantages1-2" style="width: 240px">
            多轮会话技术，适用于询问微信、时间预约、身份验证等场景
          </div>
        </div>
        <div class="productAdvantages1">
          <div class="productAdvantagesImg4"></div>
          <div class="productAdvantages1-1" style="width: 88px">识别准确</div>
          <div class="productAdvantages1-2" style="width: 245px">
            识别准确准确识别语音意图、准确挖掘用户需求、准确筛选用户意向
          </div>
        </div>
      </div>
      <div class="productAdvantages" style="margin: 0 auto">
        <div class="productAdvantages1">
          <div class="productAdvantagesImg5"></div>
          <div class="productAdvantages1-1" style="width: 89px">用户画像</div>
          <div class="productAdvantages1-2" style="width: 217px">
            用户画像数据建模，实时分析用户性别、年龄、购买意向
          </div>
        </div>
        <div class="productAdvantages1">
          <div class="productAdvantagesImg6"></div>
          <div class="productAdvantages1-1" style="width: 89px">数据统计</div>
          <div class="productAdvantages1-2" style="width: 217px">
            对海量通话数据进行信息发掘 和统计、了解客户和市场
          </div>
        </div>
        <div class="productAdvantages1">
          <div class="productAdvantagesImg7"></div>
          <div class="productAdvantages1-1" style="width: 89px">话术配置</div>
          <div class="productAdvantages1-2" style="width: 166px">
            开放对话管理引擎 可自主配置机器人话术
          </div>
        </div>
        <div class="productAdvantages1">
          <div class="productAdvantagesImg8"></div>
          <div class="productAdvantages1-1" style="width: 89px">人机协作</div>
          <div class="productAdvantages1-2" style="width: 229px">
            意向客户或问题未解决的客户 可实时转人工，提升客户满意度
          </div>
        </div>
      </div>
    </div>
    <!--  行业应用  -->
    <div style="background: #f8f8f8">
      <div class="systemTools-1">
        <!--  -->
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">行业应用</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2">实践验证，数智未来无限可能</div>
        </div>

        <!-- 行业应用 -->
        <div class="industry" style="margin-top: 60px">
          <!-- 行业导航 -->
          <div class="industryLeft" style="">
            <div
              v-for="(item, index) in industryList"
              :key="index"
              :class="[flag2 == item.id ? 'industryLeft-1s' : 'industryLeft-1']"
              @mouseover="changeIndustry(item.id)"
            >
              {{ item.titel }}
            </div>
          </div>
          <!-- 行业详情 -->
          <div class="" style="width: 940px; height: 600px">
            <div class="swiper-container2">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <!-- <div class="" style="width:607px;height:420px;">应用领域</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">应用领域</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>随时掌握客户动态信息，做出合理计划与部署</li>
                        <li>获取广告内容打开信息，主动出击不再被动等待</li>
                        <li>快速与客户建立高效联系</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当我们业务员看到自己发出的朋友圈活动广告信息被客户打开十几次的时候，我们就知道这个客户离下单不远了，这时我们会及时给这个客户再发一封详尽的产品介绍，提前做好下一步营销准备，我们很多客户都是这样成交的。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>企业服务</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">企业服务</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>随时掌握客户动态信息，做出合理计划与部署</li>
                        <li>获取广告内容打开信息，主动出击不再被动等待</li>
                        <li>快速与客户建立高效联系</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当我们业务员看到自己发出的朋友圈活动广告信息被客户打开十几次的时候，我们就知道这个客户离下单不远了，这时我们会及时给这个客户再发一封详尽的产品介绍，提前做好下一步营销准备，我们很多客户都是这样成交的。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>零售行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">零售行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>可以帮你解决营销电话过多被封号的问题</li>
                        <li>依据评分优先处理销售线索</li>
                        <li>让业务员和客户微信互动快捷简单</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        方大SCRM给我们公司开展业务提供了新的思路和现实的解决方案，电销的解决方案和微信私域流量的裂变为我们公司获客提供了全新的理念，对我们互联网服务企业真是一款不可多得好产品
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>教育行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">教育行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>借助私域流量，发现更多潜在客户</li>
                        <li>沉淀客户数据资产，高效管理每个客户</li>
                        <li>在微信中持续给客户发送内容种草，促进成交</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当时选择方大SCRM的主要原因是
                        方大SCRM使用起来太方便了。所有客户资料在手机上一目了然，通过这个系统管理员工和客户效率提升不少，员工开发的客户情况和跟进情况我都能通过这个系统来掌握了。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>装修行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">装修行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>全方位勾勒客户画像，快速了解客户个人风格和喜好</li>
                        <li>有效盘活沉睡客户，强化客户管理中的日常维护</li>
                        <li>利用标签与分组，实现客户层级化管理</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        方大SCRM容素材包：提供文章、文件、海报、商品等丰富的内容素材包；可以根据客户的标签给客户发送对应的素材，根据客户的浏览轨迹，分析客户心理，促进客户合作签单
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>地产公司</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">地产公司</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>通过销售漏斗，指导业务过程精细化、数据化</li>
                        <li>方大可以自动筛选，自动分配给销售跟进</li>
                        <li>
                          通过方大提供的CRM组件，帮你沉淀客户数据资产，高效管理每一个客户
                        </li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        当我们业务员看到自己发出的朋友圈活动广告信息被客户打开十几次的时候，我们就知道这个客户离下单不远了，这时我们会及时给这个客户再发一封详尽的产品介绍，提前做好下一步营销准备，我们很多客户都是这样成交的。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>金融行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">金融行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>快速准确找到意向客户资源</li>
                        <li>有效管理加盟商信息</li>
                        <li>利用标签与分组，实现加盟代理商层级化管理</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        之前我们公司有多年的客户数据沉淀，使用方大SCRM后可以很好地对这些沉淀的数据进行科学的分组分类、贴标签等操作，从而有针对性地营销，做到多次触达盘活老客户。而针对暂时没有客户资源的新员工来说，公海就是他们优质的资源库，可以自由领取使用跟进，快速成长。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>医美行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">医美行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>给一线销售制定合理的任务计划</li>
                        <li>通过量化指标，查看进度，保证任务的执行落地</li>
                        <li>规范化沉淀客户资源</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        根据销售和客户的互动行为，以及客户在内容中留下的行为轨迹，智能计算得出互动指数。销售可以结合互动指数，对不同层级的客户使用针对策略去跟进，促使销售转化率达到目标或预期
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <!-- <div>休闲娱乐行业</div> -->
                  <div class="" style="width: 99%; height: 95%">
                    <div class="application"><span style="cursor: pointer;">休闲娱乐行业</span></div>
                    <div class="application1">
                      <div class="application1_1"><span style="cursor: pointer;">方大解决方案可以帮你实现</span></div>
                      <ul class="list">
                        <li>快速与客户建立高效联系潜能</li>
                        <li>快速准确找到意向客户资源利润</li>
                        <li>借助人工智能实现个性化推荐潜客</li>
                      </ul>
                    </div>
                    <div class="application2">
                      <div class="application2_1">客户说：</div>
                      <div class="application2_2" style="margin-top: 23px">
                        之前我们公司有多年的客户数据沉淀，使用方大SCRM后可以很好地对这些沉淀的数据进行科学的分组分类、贴标签等操作，从而有针对性地营销，做到多次触达盘活老客户。而针对暂时没有客户资源的新员工来说，公海就是他们优质的资源库，可以自由领取使用跟进，快速成长。
                      </div>
                      <div class="application2_2">
                        某企业服务公司 | 业务经理 | 王先生
                      </div>
                    </div>
                    <div class="application3">
                      <div class="application3_1">案例客户</div>
                      <div class="application3_2"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="swiper-pagination"></div> -->
              <!-- <div class="swiper-scrollbar"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-top></footer-top>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import footerTop from "@/components/footerTop.vue";
import Footer from "@/components/footer.vue";
import { Swiper, Navigation, Pagination, Autoplay, Scrollbar } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar]);
import eventBus from '../config/eventBus.js'
export default {
  name: "",
  components: {
    HeadNav,
    footerTop,
    Footer,
  },
  data() {
    return {
      industryList: [
        {
          id: 0,
          titel: "应用领域",
        },
        {
          id: 1,
          titel: "企业服务",
        },
        {
          id: 2,
          titel: "零售行业",
        },
        {
          id: 3,
          titel: "教育行业",
        },
        {
          id: 4,
          titel: "装修行业",
        },
        {
          id: 5,
          titel: "地产公司",
        },
        {
          id: 6,
          titel: "金融行业",
        },
        {
          id: 7,
          titel: "医美行业",
        },
        {
          id: 8,
          titel: "休闲娱乐行业",
        },
      ],
      flag2: 0,
      Myswiper2: null,
    };
  },
  mounted() {
    var that = this;
    // this.timeChange();
    that.Myswiper2 = new Swiper(".swiper-container2", {

      direction: "vertical",
      navigation: false,
      on: {
        transitionEnd: function () {
          that.flag2 = this.activeIndex;
          // console.log(that.flag2);
        },
      },
    });
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    changesize(){
      eventBus.$emit('add',true)
    },
    changeIndustry(e) {
      // console.log(e,'654')
      this.flag2 = e;
      this.Myswiper2.slideTo(e, 1000, false);
    },
  },
};
</script>

<style src="@/style/QiXunCloudAi.css" scoped></style>
<style lang="scss" scoped>
.swiper-container2 {
  width: 960px;
  height: 600px;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

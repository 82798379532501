<template>
  <!-- 解决方案 -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">深入洞察行业需求</div>
            <div class="backgroundImg2">为你提供专业的行业解决方案</div>
          </div>
        </div>
      </header>
    </div>

    <!-- 行业痛点 -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">行业痛点</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <!--  -->
      <div
        class="industry"
        style="display: flex; justify-content: space-between"
      >
        <div class="industry1">
          <div class="industry1Img1 industry1_1_1"></div>
          <div class="industry1_1">宣传推广效率低下</div>
          <div class="industry1_2">
            传统教育培训行业只通过电视、广告媒体等方式进行宣传推广，效果难以达到预期。目前很多教育培训机构仍然采用“沿街叫卖”的推广方式，这种方式时间成本大产出低，而且难以让潜在学员印象良好。
          </div>
        </div>
        <div class="industry1">
          <div class="industry1Img2 industry1_1_1"></div>
          <div class="industry1_1">业务难整合，无法统一管理</div>
          <div class="industry1_2">
            教育培训机构往往有很多校区、多业务的特点，怎样才能将业务信息和话务信息集中，做到统一的管理和分析，是很多教育机构面临的问题。
          </div>
        </div>
        <div class="industry1">
          <div class="industry1Img3 industry1_1_1"></div>
          <div class="industry1_1">学员信息管理与服务体验差</div>
          <div class="industry1_2">
            学员信息记录不规范，搜索客户关系信息时效率低下，易把信息弄混记错，资料保密性差。拨打热线电话经常占线，来电咨询重复沟通，迟迟无法转接至相应人员。缺乏沟通凭证，没有相应的文字记录和录音关联。
          </div>
        </div>
      </div>
    </div>

    <!-- 解决方案 -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">解决方案</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <!--  -->
      <div class="industry">
        <div class="programme1">
            <div class="programme1-1">
              <div class="programme1span">01</div>
            </div>
            <div class="programme1-1-2">电话营销主动外呼</div>
          <div class="programme1-2">
            <div>主动外呼彻底打破了传统电话销售中的手动拨号、手动录入的流程，转为由系统自动呼出，将已接通客户自动分配至空闲坐席的方式，</div>
            <div>极大的提升电话营销的呼出效率，据实践统计人均有效通话数可达500-680次，进而提高成单效率。</div>
          </div>
          <div class="flex jc">
            <div class="programme-01"></div>
          </div>
        </div>
        <div class="programme1">
            <div class="programme1-1">
              <div class="programme1span">02</div>
            </div>
            <div class="programme1-1-2">语音外呼</div>
          <div class="programme1-2">
            <div>系统自动外呼客户手机，客户摘机后系统自动播放语音广告，也可按键引导客户查听相关语音，</div>
            <div>语音广告可提示客户如感兴趣可按键转入座席应答处理。</div>
          </div>
          <div class="flex jc">
            <div class="programme-02"></div>
          </div>
        </div>
        <div class="programme1">
            <div class="programme1-1">
              <div class="programme1span">03</div>
            </div>
            <div class="programme1-1-2">信息群发</div>
          <div class="programme1-2">
            <div>呼叫中心可对接短信平台、邮件服务，对指定学员发送营销推广信息、服务关怀信息等。</div>
          </div>
        </div>
        <div class="programme1">
            <div class="programme1-1">
              <div class="programme1span">04</div>
            </div>
            <div class="programme1-1-2">对接其他入口</div>
          <div class="programme1-2">
            <div>呼叫中心不仅可以管理电话入口，还对接业务推广网站、微信公众账号等。为学员咨询、服务、营销提供更多、更便捷的服务。</div>
          </div>
          <div class="flex jc">
            <div class="programme-04" style="margin-bottom:20px;"></div>
          </div>
        </div>
        <div class="programme1">
            <div class="programme1-1">
              <div class="programme1span">05</div>
            </div>
            <div class="programme1-1-2">学员信息记录</div>
          <div class="programme1-2">
            <div>使用专业CRM统一管理所有意向学员的线索，将学员线索掌握在企业手里。企讯通呼叫中心提供专业CRM，界面操作简单，易用灵活。</div>
            <div>同时提供学员跟进提醒。通过对线索的来电号码、来电时间和通话记录的整合，实现高效的客户线索统一管理和分配。</div>
          </div>
        </div>
        <div class="programme1">
            <div class="programme1-1">
              <div class="programme1span">06</div>
            </div>
            <div class="programme1-1-2">统一号码呼入呼出</div>
          <div class="programme1-2">
            <div>
              平台支持统一号码呼入呼出，可以帮助总部加强对于服务的管理，提升企业形象。企业可对外展示统一固话号码或400号码。
            </div>
          </div>
        </div>
        <div class="programme1">
            <div class="programme1-1">
              <div class="programme1span">07</div>
            </div>
            <div class="programme1-1-2">来去电弹屏</div>
          <div class="programme1-2">
            <div>来电时弹出学员信息，座席第一时间知道学员详细信息、过往沟通记录，提升学员体验。</div>
          </div>
        </div>
        <div class="programme1">
            <div class="programme1-1">
              <div class="programme1span">08</div>
            </div>
            <div class="programme1-1-2">智能路由</div>
          <div class="programme1-2">
            <div>通过智能路由可实现来电智能分配。如VIP客户直接接入专属座席，无需等待和语音导航。</div>
            <div>按通话记录，自动转接至分机或对应坐席接听，可直接接入上次通话座席。</div>
          </div>
        </div>
        <div class="programme1">
            <div class="programme1-1">
              <div class="programme1span">09</div>
            </div>
            <div class="programme1-1-2">自助查询</div>
          <div class="programme1-2">
            <div>学员呼叫客服号码后，进入自动语音导航菜单，在这里，学员可以选择收听相关服务信息的介绍，例如：课程介绍、个人课程信息等等。</div>
            <div>无需人工参与就可以得到咨询服务，完全由学员自主查询，大大减少了人工参与，同时也提高了服务质量。</div>
          </div>
        </div>
        <div class="programme1">
            <div class="programme1-1">
              <div class="programme1span">10</div>
            </div>
            <div class="programme1-1-2">统计报表</div>
          <div class="programme1-2">
            <div>呼叫中心系统具有清晰的统计报表功能。详尽的话务统计分析和业务分类统计分析为呼叫中心管理人员提供丰富、直观的统计报表和图表，</div>
            <div>通过对这些报表数据的分析，可以为呼叫中心管理人员提供科学管理、有效控制、优化配置、决策支持的有力工具。</div>
          </div>
        </div>
      </div>
      <div style="width: 1200px; padding-bottom: 82px; margin: 0 auto">
        <div class="back" @click="$router.go(-1)">返回</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "",
  components: {
    HeadNav,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style src="@/style/SolutionDetails.css" scoped></style>
<style lang="scss" scoped></style>

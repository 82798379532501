<template>
  <!-- 系统接入合作 -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">系统接入合作</div>
            <div class="backgroundImg2">SYSTEMS</div>
          </div>
        </div>
      </header>
    </div>

    <!-- 系统概述 -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">系统概述</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <!--  -->
      <div class="systemTools-1 flex jc-space" style="margin-top: 60px">
        <div class="summary">
          <div class="summaryImg1"></div>
          <div class="summary1">宣传推广效率低下(测试）</div>
          <div class="summary2">
            传统教育培训行业只通过电视、广告媒体等方式进行宣传推广，效果难以达到预期。目前很多教育培训机构仍然采用“沿街叫卖”的推广方式，这种方式时间成本大产出低，而且难以让潜在学员印象良好。
          </div>
        </div>
        <div class="summary">
          <div class="summaryImg2"></div>
          <div class="summary1">业务难整合，无法统一管理(测试）</div>
          <div class="summary2">
            教育培训机构往往有很多校区、多业务的特点，怎样才能将业务信息和话务信息集中，做到统一的管理和分析，是很多教育机构面临的问题。
          </div>
        </div>
        <div class="summary">
          <div class="summaryImg3"></div>
          <div class="summary1">宣传推广效率低下(测试）</div>
          <div class="summary2">
            学员信息记录不规范，搜索客户关系信息时效率低下，易把信息弄混记错，资料保密性差。拨打热线电话经常占线，来电咨询重复沟通，迟迟无法转接至相应人员。缺乏沟通凭证，没有相应的文字记录和录音关联。
          </div>
        </div>
      </div>
    </div>

    <!-- 合作方案 -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">合作方案</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <!-- 合作方案 -->
      <div class="programme">
        <div class="programme1">
          <div class="programme1-1">
            <div class="flex">
              <div class="programme1span">1</div>
              <div class="programme1span1"></div>
            </div>
            <div class="programme1-1-2">宣传推广效率低下</div>
          </div>
          <div class="programme1-2" style="height: 38px">
            <div>
              主动外呼彻底打破了传统电话销售中的手动拨号、手动录入的流程，转为由系统自动呼出，将已接通客户自动分配至空闲坐席的方式，
            </div>
            <div>
              极大的提升电话营销的呼出效率，据实践统计人均有效通话数可达500-680次，进而提高成单效率。
            </div>
          </div>
        </div>
        <div class="programme1">
          <div class="programme1-1">
            <div class="flex">
              <div class="programme1span">2</div>
              <div class="programme1span1"></div>
            </div>
            <div class="programme1-1-2">语音外呼</div>
          </div>
          <div class="programme1-2" style="height: 38px">
            <div>
              系统自动外呼客户手机，客户摘机后系统自动播放语音广告，也可按键引导客户查听相关语音，
            </div>
            <div>语音广告可提示客户如感兴趣可按键转入座席应答处理。</div>
          </div>
        </div>
        <div class="programme1">
          <div class="programme1-1">
            <div class="flex">
              <div class="programme1span">3</div>
              <div class="programme1span1"></div>
            </div>
            <div class="programme1-1-2">信息群发</div>
          </div>
          <div class="programme1-2" style="height: 14px">
            <div>
              呼叫中心可对接短信平台、邮件服务，对指定学员发送营销推广信息、服务关怀信息等。
            </div>
          </div>
        </div>
        <div class="programme1">
          <div class="programme1-1">
            <div class="flex">
              <div class="programme1span">4</div>
              <div class="programme1span1"></div>
            </div>
            <div class="programme1-1-2">对接其他入口</div>
          </div>
          <div class="programme1-2" style="height: 14px">
            <div>
              呼叫中心不仅可以管理电话入口，还对接业务推广网站、微信公众账号等。为学员咨询、服务、营销提供更多、更便捷的服务。
            </div>
          </div>
        </div>
      </div>

      <!--  -->
      <div style="width: 1200px; margin: 0 auto; padding-bottom: 114px">
        <div class="back">我要合作</div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "",
  components: {
    HeadNav,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {
    // this.timeChange();
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {},
};
</script>

<style src="@/style/Systems.css" scoped></style>
<style lang="scss" scoped></style>
